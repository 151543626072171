import React from "react";
import { Modal, Button } from "react-bootstrap";
import cofStyles from "../styles/confirm.module.css";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
const DeleteConfirm = ({
  showModal,
  hideModal,
  confirmModal,
  message,
  header,
}) => {
  return (
    <Modal
      dialogClassName={cofStyles.confirm}
      show={showModal}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <WarningAmberRoundedIcon className={cofStyles.cofWarning} />
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => confirmModal()}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirm;
