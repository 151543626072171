import React from "react";
import Tooltip from "@mui/material/Tooltip";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
const VisibilityOff = ({ onClick, className }) => (
  <Tooltip title="Show column/enable filter">
    <VisibilityOffOutlinedIcon onClick={onClick} className={className} />
  </Tooltip>
);

export default VisibilityOff;
