import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Report from "./components/Report";
import Home from "./components/Home";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReportFinder from "./components/ReportFinder";
import CommentFinder from "./components/CommentFinder";
import NoteFinder from "./components/NoteFinder";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import ViewComment from "./components/ViewComment";
import { generateInitialToken } from "../src/nexus/nexus.js";
import axios from "axios";
import store from "./store/store";
import appStyles from "./styles/app.module.css";
import { ThreeDots } from "react-loader-spinner";
function MainApp() {
  const [msalInstance, setMsalInstance] = useState(null);
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);
  //const dispatch = useDispatch();
  useEffect(() => {
    const xInitial = generateInitialToken(process.env.REACT_APP_X_INITIAL);

    axios
      .get("/api/getAuthConfig", {
        headers: {
          Authorization: `Bearer ${xInitial}`,
        },
      })
      .then((response) => {
        const authConfig = {
          clientId: response.data.clientId,
          authority: response.data.authority,
        };

        const pca = new PublicClientApplication({
          auth: authConfig,
          cache: {
            storeAuthStateInCookie: false,
          },
          system: {
            loggerOptions: {
              loggerCallback: (level, message, containsPII) => {},
              logLevel: "Verbose",
            },
          },
        });

        setMsalInstance(pca);
        setIsMsalInitialized(true);
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
      });
  }, []);
  if (!isMsalInitialized) {
    return (
      <div className={appStyles.introContainer}>
        <div className={appStyles.introText}>
          <ThreeDots color="#186d90" height={250} width={250} />
        </div>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </React.StrictMode>
        <Routes>
          {/* <Route index element={<App />}></Route> */}
          <Route path="report" element={<Report />} />
          <Route path="createReport" element={<Report />} />
          <Route path="findReport" element={<ReportFinder isCopy={false} />} />
          <Route path="copyReport" element={<ReportFinder isCopy={true} />} />
          <Route path="findComment" element={<CommentFinder />} />
          <Route path="viewComment" element={<ViewComment />} />
          <Route path="findNote" element={<NoteFinder />} />

          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<MainApp />);
reportWebVitals();
// const authConfig = {
//   clientId: clientId,
//   authority: authority,
//   authKey:authKey,
// };
