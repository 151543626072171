import React from "react";
import SlidingPane from "react-sliding-pane";
import slideStyles from "../styles/slide.module.css";
import { useNavigate } from "react-router-dom";

export default function ReportPane({ isOpen, onClose, reportLinks }) {
  const navigate = useNavigate();

  return (
    <SlidingPane
      className={slideStyles.slide}
      title="Report Creator"
      headerClassName={slideStyles.slidePane}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className={slideStyles.slideHead}>Report Creator</div>
      <div className={slideStyles.slideBox}>
        {reportLinks.map((pageLink) => (
          <div
            className={`${slideStyles.slideBoxButton} ${pageLink.class}`}
            onClick={() => {
              onClose();
              navigate(`/${pageLink.path}`);
            }}
            key={pageLink.path}
          >
            <div className={slideStyles.reportBoxTextHead}>{pageLink.head}</div>
            <div className={slideStyles.reportBoxTextSub}>{pageLink.sub}</div>
          </div>
        ))}
      </div>
    </SlidingPane>
  );
}
