import React, { useEffect, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import crStyles from "../styles/comment.module.css";
import { IoMdLink } from "react-icons/io";
import { FiInfo } from "react-icons/fi";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import * as Cts from "../nexus/constants.js";
import Select from "react-select";
import { EditTextarea } from "react-edit-text";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiExternalLink } from "react-icons/fi";
import { reportContext, reportDispatchContext } from "./Report";
import CommentDetail from "./CommentDetail";
import DeleteConfirm from "./DeleteConfirm";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCurrentTime, determineTrend } from "../nexus/nexus";
import { useSelector, useDispatch } from "react-redux";
import LinkBoxNotes from "./LinkBoxNotes";
import { setDeletedComments } from "../store/store";
import CommentLinker from "./CommentLinker";
import axios from "axios";

function Comment(props) {
  const dispatchStore = useDispatch();
  const [crHeaderClass, setCrHeaderClass] = useState(crStyles.crHeaderDef);
  const {
    id,
    header,
    desc,
    rag,
    reportId,
    prId,
    catId,
    view,
    index,
    prIndex,
    total,
  } = props;

  const [comment, setComment] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [currentHeaderLabel, setCurrentHeaderLabel] = useState("");
  const [isPackageLocked, setIsPackageLocked] = useState(false);
  const [isHeaderChanged, setIsHeaderChanged] = useState(false);
  const [isDescChanged, setIsDescChanged] = useState(false);
  const [isRagChanged, setIsRagChanged] = useState(false);
  const [showRag, setShowRag] = useState(false);
  const [showLinkBox, setShowLinkBox] = useState(false);
  const [showLinkComment, setShowLinkComment] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const configPCC = JSON.parse(localStorage.getItem("pcc"));
  const report = useContext(reportContext);
  const dispatchReport = useContext(reportDispatchContext);

  const deletedComments = useSelector((state) => state.deletedComments);
  const currentUser = useSelector((state) => state.value);
  const currentUserName = currentUser ? currentUser.split(" (")[0] : "";

  const shouldUpdateHeader = (currentLabel, newLabel) => {
    return currentLabel !== newLabel;
  };

  const updateLastEdited = () => {
    console.log("handle update project is called");
    dispatchReport({
      type: "UPDATE_PROJECT",
      payload: {
        id: prId,
        keys: [
          {
            key: "lastEdited",
            value: getCurrentTime(),
          },
        ],
      },
    });
  };

  const handleUpdate = (fieldKey, val, setChanged) => {
    console.log(`handle update comment is called ${fieldKey} -`, val);
    if (fieldKey === "active") {
      setDeleteConfirm(false);
      dispatchStore(
        setDeletedComments([
          {
            id: comment.id,
            linkedComms: comment.linkedComms,
            time: getCurrentTime(),
          },
          ...deletedComments,
        ])
      );
      dispatchReport({
        type: "UNLINK_DELETED_COMMENT_NOTES",
        payload: {
          value: comment.id,
        },
      });
    }
    if (setChanged) {
      setChanged(true);
    }
    const updatedComment = {
      id: id,
      key: fieldKey,
      value: val,
      reportId: reportId,
      prId: prId,
      catId: catId,
    };

    dispatchReport({
      type: "UPDATE_COMMENT",
      payload: updatedComment,
    });

    updateLastEdited();

    setComment(updatedComment);
  };
  const handleUpdateHeader = (fieldKey, val, setChanged, isLocked) => {
    if (setChanged) {
      setChanged(true);
    }
    const updatedComment = {
      id: id,
      key: fieldKey,
      value: val,
      reportId: reportId,
      prId: prId,
      catId: catId,
    };

    dispatchReport({
      type: "UPDATE_COMMENT_LOCKED_PKG",
      payload: updatedComment,
    });

    setComment(updatedComment);
  };
  const updateHistoryCommentDetail = (historyItem) => {
    const updatedComment = {
      ...comment,
      history: [historyItem, ...comment.history],
    };

    setComment(updatedComment);
  };

  const handleCloseLink = () => {};
  const handleClose = () => {
    setIsDescChanged(false);
    setIsHeaderChanged(false);
    setIsRagChanged(false);
  };
  const handleUpdateComment = async (updatedComment) => {
    setIsDescChanged(false);
    setIsHeaderChanged(false);
    setIsRagChanged(false);
    const replaceComment = {
      ...updatedComment,
      prId: prId,
      catId: catId,
      reportId: reportId,
    };
    dispatchReport({
      type: "REPLACE_COMMENT",
      payload: replaceComment,
    });
    updateLastEdited();
    if (view) {
      const request = {
        report: report,
      };
      await axios
        .post("/updateReport", request)
        .then((res) => console.log("updated"))
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchComment = async () => {
    const currentProject = report.projects.find((p) => p.id === prId);
    if (
      currentProject &&
      currentProject.isPackageLocked &&
      shouldUpdateHeader(currentHeaderLabel, currentProject.name.label)
    ) {
      handleUpdateHeader(
        "header",
        { label: currentProject.name.label, value: currentProject.name.label },
        setIsHeaderChanged,
        currentProject.isPackageLocked
      );
      // Optionally update the current label state if the header is updated
      setCurrentHeaderLabel(currentProject.name.label);
    }

    if (currentProject.isPackageLocked) {
      setIsPackageLocked(currentProject.isPackageLocked);
    }
    const currentComment = currentProject
      ? currentProject.categories
          .find((ct) => ct.id === catId)
          .comments.find((c) => c.id === id)
      : null;
    setComment(currentComment);
  };
  const handleLinking = () => {
    setShowLinkComment(!showLinkComment);
  };
  const headIcons = [
    {
      icon: IoMdLink,
      title: view ? "View linked comments" : "Link comment",
      action: handleLinking,
    },
  ];

  useEffect(() => {
    fetchComment();
  }, [report]);

  const popCommentDetail = () => {
    const updatedComment = {
      ...comment,
      history: [
        ...(isDescChanged
          ? [
              {
                time: getCurrentTime(),
                editor: currentUserName,
                action: "updated",
                field: "Description",
                value: comment.desc,
              },
            ]
          : []),
        ...(isHeaderChanged
          ? [
              {
                time: getCurrentTime(),
                editor: currentUserName,
                action: "updated",
                field: "Header",
                value: comment.header && comment.header.value,
              },
            ]
          : []),

        ...(isRagChanged
          ? [
              {
                time: getCurrentTime(),
                editor: currentUserName,
                action: "updated",
                field: "RAG",
                value: comment.rag && comment.rag.label,
              },
            ]
          : []),
        ...comment.history,
      ],
      // ragHistory: [
      //   ...(isRagChanged &&
      //   report.from !== "" &&
      //   currentProject?.name.label !== "Select a project..." &&
      //   comment.header != ""
      //     ? [
      //         {
      //           title: report.title,
      //           project: currentProject?.name.label,
      //           header: comment.header.value,
      //           category: currentCat?.name,
      //           rag: comment.rag?.label,
      //         },
      //       ]
      //     : []),
      //   ...comment.ragHistory,
      // ],
    };

    setComment(updatedComment);
    dispatchReport({
      type: "REPLACE_COMMENT",
      payload: updatedComment,
    });
    setShowDetail(true);
  };

  const updateMetaComment = (linkedItems, history) => {
    const updatedComment = {
      ...comment,
      linkedComms: linkedItems,
      history: history,
    };

    setComment(updatedComment);
    dispatchReport({
      type: "REPLACE_COMMENT",
      payload: updatedComment,
    });
  };

  const getCardClass = (label) => {
    if (label) {
      if (label === "Green") {
        return crStyles.crBody;
      } else if (label === "Amber" || label === "Orange") {
        return crStyles.crBodyOrange;
      } else if (label === "Red") {
        return crStyles.crBodyRed;
      } else {
        // Return a default class or an empty string if needed
        return crStyles.crBody;
      }
    } else {
      return crStyles.crBody;
    }
  };

  const customCommentHeaderView = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const customCommentHeader = {
    menu: (provided) => ({
      ...provided,
      width: "190px",
    }),
  };

  //Render UI
  return (
    <Card className={crStyles.crContainer}>
      <Card.Body
        className={
          view ? getCardClass(comment?.rag?.label) : crStyles.crBodyEdit
        }
      >
        <div
          className={view ? crStyles.noteContainerView : crStyles.noteContainer}
        >
          {report &&
            comment &&
            report.notes
              .filter((note) => note.active != false)
              .map((note, index) => {
                const isCommentIncluded = note.comments.includes(comment.id);

                return (
                  isCommentIncluded && (
                    <div className={crStyles.noteOnTop}>{index + 1}</div>
                  )
                );
              })}
        </div>
        <div
          className={crStyles.crRag}
          title={
            comment && comment.header != ""
              ? ""
              : "Select a package before setting RAG."
          }
          style={{
            backgroundColor: comment && comment.rag && comment.rag.value,
          }}
          onMouseEnter={() => setShowRag(true)}
          onMouseLeave={() => setShowRag(false)}
        >
          {comment && comment.ragHistory && comment.ragHistory.length > 0 && (
            <>
              {(() => {
                const trend = determineTrend(
                  comment.ragHistory[0].rag,
                  comment.rag?.label
                );
                if (trend === "Up") {
                  return <ArrowUpward className={crStyles.arrow} />;
                } else if (trend === "Down") {
                  return <ArrowDownward className={crStyles.arrow} />;
                } else {
                  return null; // Return null if neither "Up" nor "Down"
                }
              })()}
            </>
          )}

          {comment && comment.header != "" && showRag && (
            <div className={crStyles.colorOptions}>
              <span className={crStyles.tip}></span>
              <div
                className={crStyles.colorOption}
                style={{ backgroundColor: "#008000" }}
                onClick={() =>
                  handleUpdate(
                    "rag",
                    { value: "#008000", label: "Green" },
                    setIsRagChanged
                  )
                }
              />
              <div
                className={crStyles.colorOption}
                style={{ backgroundColor: "#FFA500" }}
                onClick={() =>
                  handleUpdate(
                    "rag",
                    { value: "#FFA500", label: "Amber" },
                    setIsRagChanged
                  )
                }
              />
              <div
                className={crStyles.colorOption}
                style={{ backgroundColor: "#FF4500" }}
                onClick={() =>
                  handleUpdate(
                    "rag",
                    { value: "#FF4500", label: "Red" },
                    setIsRagChanged
                  )
                }
              />
            </div>
          )}
        </div>

        <div className={view ? crStyles.crContentView : crStyles.crContent}>
          <div className={crStyles.crTop}>
            <span className={crStyles.crId}>{comment && comment.id}</span>
            {headIcons.map((icon) => {
              return (
                <span title={icon.title}>
                  <icon.icon
                    className={
                      comment?.header?.value && !view
                        ? crStyles.crTopIcons
                        : crStyles.crTopIconsView
                    }
                    onClick={() =>
                      comment?.header?.value ? icon.action() : null
                    }
                  />
                </span>
              );
            })}
          </div>
          {showLinkComment && (
            <CommentLinker
              type="comment"
              parentId={comment.id}
              title={comment.header.value}
              linkedComms={comment.linkedComms}
              handleClose={handleCloseLink}
              show={showLinkComment}
              setShow={setShowLinkComment}
              view={view}
              ragData={comment.ragHistory}
              historyData={comment.history}
              updateMetaComment={updateMetaComment}
              isPage={false}
              user={currentUserName}
            />
          )}
          <div className={crStyles.crControls}>
            {!view ? (
              <Select
                className={crHeaderClass}
                styles={view ? customCommentHeaderView : customCommentHeader}
                isDisabled={isPackageLocked}
                placeholder={Cts.cmHeaderPlaceHolder}
                isSearchable={true}
                options={
                  configPCC &&
                  configPCC.commentHeaders.sort((a, b) =>
                    a.label > b.label ? 1 : -1
                  )
                }
                value={comment && comment.header}
                onChange={(selOption) =>
                  handleUpdate("header", selOption, setIsHeaderChanged)
                }
              />
            ) : (
              <div
                className={`${crStyles.crNameView} ${
                  comment?.copied ? crStyles.crCopy : ""
                }`}
              >
                {comment && comment.header.label}
              </div>
            )}
            <div className={crStyles.crLine}></div>
            {!view ? (
              <div className={crStyles.crDesc}>
                <EditTextarea
                  value={comment && comment.desc}
                  readonly={view}
                  rows={10}
                  onChange={(e) =>
                    handleUpdate("desc", e.target.value, setIsDescChanged)
                  }
                  className={crStyles.crAreaDesc}
                  placeholder="Enter description"
                />
              </div>
            ) : (
              <div className={`${crStyles.crDesc} ${crStyles.crviewDesc}`}>
                {comment &&
                  (comment.taggedDesc ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: comment.taggedDesc }}
                    ></div>
                  ) : (
                    comment.desc?.split("\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                      </React.Fragment>
                    ))
                  ))}
              </div>
            )}
          </div>

          <div className={crStyles.colBottom}>
            {!view && (
              <div
                title="Link/Unlink note"
                onMouseLeave={() => setShowLinkBox(false)}
                className={crStyles.colInfo}
              >
                <FiInfo
                  className={crStyles.iconinfoEx}
                  onClick={() => setShowLinkBox(!showLinkBox)}
                ></FiInfo>
                <div
                  onMouseEnter={() => setShowLinkBox(true)}
                  onMouseLeave={() => setShowLinkBox(false)}
                >
                  {showLinkBox && (
                    <LinkBoxNotes
                      commentid={comment.id}
                      isDetail={false}
                      reportId={report.id}
                      currentNotes={report.notes}
                      prId={prId}
                      catId={catId}
                      doRefresh={true}
                      updateHistoryCommentDetail={updateHistoryCommentDetail}
                    />
                  )}
                </div>
              </div>
            )}

            <div className={crStyles.colEx}>
              <label className={crStyles.crLabelExCol}> More Details </label>
              <FiExternalLink
                className={crStyles.iconColEx}
                onClick={() => popCommentDetail()}
              />
            </div>
            {!view && (
              <>
                <div title="Delete comment" className={crStyles.colDelete}>
                  <RiDeleteBin6Line
                    className={crStyles.iconDelete}
                    // onClick={() => handleUpdate("active", false)}
                    onClick={() => setDeleteConfirm(true)}
                  />
                </div>
                <DeleteConfirm
                  showModal={deleteConfirm}
                  hideModal={() => setDeleteConfirm(false)}
                  confirmModal={() => handleUpdate("active", false)}
                  message={`Are you sure to delete the comment - ${
                    comment && comment.id
                  }?`}
                  header={comment && `DELETE COMMENT`}
                />
              </>
            )}
          </div>
        </div>
        {comment && (
          <CommentDetail
            currentComment={comment}
            handleUpdateComment={handleUpdateComment}
            handleClose={handleClose}
            show={showDetail}
            setShow={setShowDetail}
            view={view}
            user={currentUserName && currentUserName}
            project={report.projects.find((p) => p.id === prId).name.label}
            category={
              report.projects
                .find((p) => p.id === prId)
                .categories.find((ct) => ct.id === catId).name
            }
            isPage={false}
            canEdit={true}
            commentMeta={null}
            updateMetaComment={updateMetaComment}
            prId={prId}
            catId={catId}
            isPackageLocked={isPackageLocked}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default Comment;
