import { useMemo, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";

import Tooltip from "@mui/material/Tooltip";
import finderStyles from "../styles/finder.module.css";

export default function useColumns({ isCopy }) {
  const navigate = useNavigate();
  const statusColorMap = {
    Completed: "#008000",
    "Under Review": "#0073CF",
    Draft: "#FFA500",
  };

  const BoxHeader = ({ backgroundColor }) => {
    const labelStyle = {
      backgroundColor,
    };
    return (
      <>
        <label className={finderStyles.ragLabel}>RAG</label>
        <label className={finderStyles.colorBox} style={labelStyle}></label>
      </>
    );
  };

  const handleCopy = (val) => {
    navigate(`/report?ID=${val}&copy`);
  };

  const dateSortFunction = (rowA, rowB, columnId) => {
    const dateA = new Date(
      rowA.values[columnId].split("/").reverse().join("-")
    );
    const dateB = new Date(
      rowB.values[columnId].split("/").reverse().join("-")
    );

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };

  const multiSelectFilter = (rows, columnId, filterValue) => {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
          filterValue.includes(String(row.original[columnId]))
        );
  };
  const multiSelectFilterProject = (rows, columnId, filterValue) => {
    if (filterValue.length === 0) {
      return rows;
    }

    return rows.filter((row) => {
      const columnValues = String(row.original[columnId]).split(",");
      return columnValues.some((value) => filterValue.includes(value.trim()));
    });
  };

  const customDateFilter = (rows, columnIds, filterValue) => {
    const filteredRows = rows.filter((row) => {
      const dateValue = row.original[columnIds[0]];

      const [rowDay, rowMonth, rowYear] = dateValue.split("/");
      const rowDate = new Date(`${rowYear}-${rowMonth}-${rowDay}`);

      if (Array.isArray(filterValue) && filterValue.length === 2) {
        const [startDate, endDate] = filterValue;
        const start = new Date(startDate);
        const end = new Date(endDate);

        return rowDate >= start && rowDate <= end;
      } else {
        const [firstDate] = filterValue;
        const start = new Date(firstDate);

        return rowDate >= start;
      }
    });

    return filteredRows;
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "cid",
        filter: multiSelectFilter,
        show: false,
        showInCopy: true,
        copy: true,
        width: 50,
        // width: "auto",
        Cell: (props) => {
          const id = props.row.original.id;
          return (
            <Tooltip title={`Copy ${id}`}>
              <div
                className={finderStyles.columnId}
                onClick={() => handleCopy(id)}
              >
                <FileCopyTwoToneIcon className={finderStyles.homeIcon} />
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        width: 120,
        // width: "auto",
        Cell: (props) => {
          return <p style={{ fontWeight: 600 }}>{props.value}</p>;
        },
      },
    
      {
        Header: "Title",
        accessor: "viewTitle",
        width: 200,
        filter: multiSelectFilter,
        show: true,
        showInCopy: false,
        copy:false,
        Cell: (props) => {
          const id = props.row.original.id;

          return (
            <Tooltip title={`View ${id}`}>
              <Nav.Link
                onClick={() => navigate(`/report?ID=${id}`)}
                style={{
                  color: "#0073CF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                <p style={{ fontWeight: 400 }}>{props.value}</p>
              </Nav.Link>{" "}
            </Tooltip>
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
        width: 200,
        filter: multiSelectFilter,
        show: false,
        copy: true,
        showInCopy: true,
        Cell: (props) => {
          const id = props.row.original.id;

          return (
            <Tooltip title={`Copy ${id}`}>
              <Nav.Link
                onClick={() => handleCopy(id)}
                style={{
                  color: "#0073CF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                <p style={{ fontWeight: 400 }}>{props.value}</p>
              </Nav.Link>{" "}
            </Tooltip>
          );
        },
      },
      {
        Header: "Type",
        accessor: "reportType",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        width: 120,
      },
      {
        Header: "Author",
        accessor: "createdBy",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
      },
      {
        Header: "Reporting Period",
        accessor: "from",
        filter: customDateFilter,
        show: true,
        showInCopy: true,
        width: 170,
        sortType: dateSortFunction,
      },

      {
        Header: () => <BoxHeader backgroundColor="green" />,
        accessor: "ragGreen",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        width: 120,
      },
      {
        Header: () => <BoxHeader backgroundColor="orange" />,
        accessor: "ragOrange",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        width: 120,
      },
      {
        Header: () => <BoxHeader backgroundColor="rgb(255, 69, 0)" />,
        accessor: "ragRed",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        width: 120,
      },
      {
        Header: "Status",
        accessor: "status",
        filter: multiSelectFilter,
        show: true,
        showInCopy: true,
        Cell: (props) => {
          return (
            <p style={{ color: statusColorMap[props.value], fontWeight: 400 }}>
              {props.value}
            </p>
          );
        },
      },
      {
        Header: "Projects",
        accessor: "projectNames",
        filter: multiSelectFilterProject,
        show: true,
        showInCopy: true,
      },
      {
        Header: "Date Modified",
        accessor: "lastModifiedDate",
        filter: customDateFilter,
        show: true,
        showInCopy: true,
        sortType: dateSortFunction,
      },
    ],
    [isCopy]
  );

  return columns;
}
