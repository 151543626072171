import React, { useEffect, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import Category from "./Category";
import prStyles from "../styles/project.module.css";
import DeleteConfirm from "./DeleteConfirm";
import "bootstrap/dist/css/bootstrap.min.css";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import * as Cts from "../nexus/constants.js";
import Select from "react-select";
import { RiDeleteBin6Line } from "react-icons/ri";
import { reportContext, reportDispatchContext } from "./Report";
import { getCurrentTime } from "../nexus/nexus";

function Project(props) {
  const [collapse, setCollapse] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [project, setProject] = useState(null);
  const [isPackageLocked, setIsPackageLocked] = useState(false);
  const { projectId, view, prIndex } = props;
  const report = useContext(reportContext);
  const dispatchReport = useContext(reportDispatchContext);
  const configPCC = JSON.parse(localStorage.getItem("pcc"));
  const customProjectSelectView = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };

  const countCatComments = (category) => {
    return category.comments
      ? category.comments.filter((comment) => comment.active).length
      : 0;
  };

  const countProjectComments = (project) => {
    let totalActiveComments = 0;

    if (project.categories) {
      totalActiveComments = project.categories.reduce(
        (count, category) => count + countCatComments(category),
        0
      );
    }

    return totalActiveComments;
  };
  const handleUpdate = (val, fieldKey) => {
    setDeleteConfirm(false);
    dispatchReport({
      type: "UPDATE_PROJECT",
      payload: {
        id: projectId,
        keys: [
          { key: fieldKey, value: val },
          // {
          //   key: "lastEdited",
          //   value: getCurrentTime(),
          // },
        ],
      },
    });
    project[fieldKey] = val;
    setProject(project);
  };

  const handleUpdateProjectName = (val, fieldKey, valid, pass) => {
    setIsPackageLocked(pass);
    dispatchReport({
      type: "UPDATE_PROJECT",
      payload: {
        id: projectId,
        keys: [
          { key: valid, value: true },
          { key: fieldKey, value: val },
          {
            key: "lastEdited",
            value: getCurrentTime(),
          },
          { key: "isPackageLocked", value: pass },
        ],
      },
    });
    project[fieldKey] = val;
    project["isPackageLocked"] = pass;
    setProject(project);
  };

  const handleUpdateClass = (val, fieldKey, valid) => {
    dispatchReport({
      type: "UPDATE_PROJECT",
      payload: {
        id: projectId,
        keys: [
          { key: valid, value: true },
          { key: fieldKey, value: val },
          {
            key: "lastEdited",
            value: getCurrentTime(),
          },
        ],
      },
    });
    project[fieldKey] = val;

    setProject(project);
  };

  const isHighestActiveCommentsOdd = () => {
    let highestActiveCommentsCount = 0;

    project?.categories.forEach((category) => {
      let highestActiveCountInCategory = 0;

      category?.comments?.forEach((comment) => {
        if (comment?.active) {
          highestActiveCountInCategory++;
        }
      });

      if (highestActiveCountInCategory > highestActiveCommentsCount) {
        highestActiveCommentsCount = highestActiveCountInCategory;
      }
    });
    if (highestActiveCommentsCount % 2 === 0) {
      return true;
    } else {
      return false;
    } // Check if it's an odd number
  };

  useEffect(() => {
    const currentProject = report.projects.find((p) => p.id === projectId);
    setProject(currentProject);
  }, [report]);

  const toggleProject = () => {
    setCollapse((collapse) => !collapse);
  };

  //Render UI
  return (
    <div
      className={`${prStyles.prContainer} ${
        isHighestActiveCommentsOdd()
          ? prStyles.pageBreakAfter
          : prStyles.pageBreakAfter
      }`}
    >
      <div className={prStyles.prTopContainer}>
        {!collapse && (
          <Card
            className={`${prStyles.prProjectContainer} ${
              view === true && prStyles.prContainerView
            }`}
          >
            <Card.Body className={prStyles.prBody}>
              <div className={prStyles.prContent}>
                <span className={prStyles.prId}>
                  ID: {project && project.name.value.split("#")[0]}
                </span>
                {!view ? (
                  <div
                    className={
                      view === true
                        ? prStyles.prTitleTextDisabled
                        : prStyles.title
                    }
                  >
                    <Select
                      className={`${prStyles.prTitleText} ${
                        project && !project.isValid && prStyles.highlight
                      }`}
                      styles={view ? customProjectSelectView : ""}
                      isDisabled={view}
                      filterOption={(option, inputValue) => {
                        //  custom filter condition here based on label and value
                        const isValueMatch = option.value
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                        const isLabelMatch = option.label
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());

                        // Keep the original filter logic if the value is not "default"
                        const isNotDefault = option.value !== "default";

                        // Return the combined filter result
                        return (isValueMatch || isLabelMatch) && isNotDefault;
                      }}
                      placeholder={Cts.prTitlePlaceHolder}
                      isSearchable={true}
                      value={project && project.name}
                      options={configPCC && configPCC.projects.sort((a, b) =>
                        a.label > b.label ? 1 : -1
                      )}
                      onChange={(selOption) =>
                        handleUpdateProjectName(
                          selOption,
                          "name",
                          "isValid",
                          selOption.pass
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className={prStyles.prNameView}>
                    {project && project.name.label}
                  </div>
                )}

                {!view && (
                  <div
                    className={
                      view == true
                        ? prStyles.prTitleTextDisabled
                        : prStyles.title
                    }
                  >
                    <label className={prStyles.prLabel}>Classification:</label>
                    <Select
                      className={`${prStyles.prTitleText} ${
                        project && !project.isValidClass && prStyles.highlight
                      }`}
                      styles={view ? customProjectSelectView : ""}
                      isDisabled={view}
                      placeholder={Cts.prClassificationPlaceHolder}
                      isSearchable={true}
                      filterOption={(option) => option.value !== "default"}
                      value={project && project.classification}
                      options={configPCC && configPCC.classifications}
                      onChange={(selOption) =>
                        handleUpdateClass(
                          selOption,
                          "classification",
                          "isValidClass"
                        )
                      }
                    />
                  </div>
                )}

                <label
                  className={`${prStyles.prLabelEditor} ${
                    view ? null : prStyles.prLabelEditorEdit
                  }`}
                  F
                >
                  Last edited:
                </label>
                <div className={prStyles.prAuthor}>
                  {project && `${project.lastEditedBy}, ${project.lastEdited}`}
                </div>

                <div
                  className={view ? prStyles.prActionsView : prStyles.prActions}
                >
                  <label className={prStyles.prLabelColEx}>
                    <MdExpandLess
                      className={prStyles.iconColCol}
                      onClick={() => toggleProject()}
                    />
                  </label>
                  {collapse ? "Expand" : "Collapse"}
                  {!view && project && countProjectComments(project) === 0 && (
                    <>
                      <span
                        className={prStyles.iconContainer}
                        title="Delete the project"
                      >
                        <RiDeleteBin6Line
                          className={prStyles.iconDelete}
                          onClick={() => setDeleteConfirm(true)}
                        />
                      </span>
                      <DeleteConfirm
                        showModal={deleteConfirm}
                        hideModal={() => setDeleteConfirm(false)}
                        confirmModal={() => handleUpdate(false, "active")}
                        message={`Are you sure to delete the project - ${
                          project && project.id
                        }?`}
                        header={project && `DELETE PROJECT`}
                      />
                    </>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
        <Category
          projectId={projectId}
          view={view}
          isRender={!collapse}
          prIndex={prIndex}
        />
      </div>

      {collapse && (
        <>
          <div className={prStyles.prNoContainer}>
            <div className={prStyles.prNoContent}>
              {project && project.name.label}
            </div>
            <MdExpandMore
              className={prStyles.iconColEx}
              onClick={toggleProject}
            />
          </div>
        </>
      )}
    </div>
  );
}
export default Project;
