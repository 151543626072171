const jwt = require("jsonwebtoken");

export const getCurrentTime = () => {
  const now = new Date();
  const date = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const amPm = hours < 12 ? "AM" : "PM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const time = `${formattedHours}:${formattedMinutes} ${amPm}`;
  const dateTime = `${date} ${time}`;
  return dateTime;
};

export const generateInitialToken = (key) => {
  const expiresIn = "30m";
  const initialToken = jwt.sign({}, key.toString(), {
    expiresIn
  });
  return initialToken;
};
export const roles = {
  Admin: "Admin",
  Owner: "Owner",
  Contributor: "Contributor",
  Viewer: "Reader",
};
export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export const statusColorMap = (label) => {
  let color = "";
  switch (label) {
    case "Green":
      color = "#008000";
      break;
    case "Amber":
      color = "#FFA500";
      break;
    case "Red":
      color = "#FF4500";
      break;
    case "Orange":
      color = "#FFA500";
      break;
  }
  return color;
};
export const determineTrend = (parentColor, childColor) => {
  if (parentColor === childColor) {
    return "No";
  } else if (
    parentColor === "Red" &&
    (childColor === "Amber" || childColor === "Green")
  ) {
    return "Up";
  } else if (
    (parentColor === "Amber" || parentColor === "Green") &&
    childColor === "Red"
  ) {
    return "Down";
  } else if (parentColor === "Amber" && childColor === "Green") {
    return "Up";
  } else if (parentColor === "Green" && childColor === "Amber") {
    return "Down";
  } else {
    return "No"; // Default to "No" if none of the above conditions match
  }
};
export const mapColorToLabel = (color) => {
  switch (color) {
    case "#FF4500":
      return "Red";
    case "#FFA500":
      return "Amber";
    case "#008000":
      return "Green";
    default:
      return "Unknown"; // Default to "Unknown" for unrecognized colors
  }
};

// Example usage:
export const SortTimeDesc = (a, b) => {
  // Handle cases where 'time' property is missing
  if (!a.time && !b.time) {
    return 0;
  } else if (!a.time) {
    return 1; // 'a' comes after 'b'
  } else if (!b.time) {
    return -1; // 'a' comes before 'b'
  }
  let timesA = null;
  let timesB = null;
  // Convert the 'time' property to Date objects for comparison
  if (a.time) {
    const [day, month, year, time] = a.time
      .match(/(\d+)\/(\d+)\/(\d+) (\d+:\d+[apm]{2})/i)
      ?.slice(1);

    // Convert 'pm' to 'PM' for correct parsing
    const formattedTimeA = a.time.toLowerCase().endsWith("pm")
      ? time.toUpperCase()
      : time;

    // Create a new Date object
    timesA = new Date(`${year}-${month}-${day}T${formattedTimeA}`);
  }
  if (b.time) {
    const [dayB, monthB, yearB, timeB] = b.time
      .match(/(\d+)\/(\d+)\/(\d+) (\d+:\d+[apm]{2})/i)
      ?.slice(1);

    // Convert 'pm' to 'PM' for correct parsing
    const formattedTimeB = b.time.toLowerCase().endsWith("pm")
      ? timeB.toUpperCase()
      : timeB;

    // Create a new Date object
    timesB = new Date(`${yearB}-${monthB}-${dayB}T${formattedTimeB}`);
  }
  // Sort in descending order based on time
  return timesB - timesA;
};
export const CustomTooltip = ({ title, children }) => (
  <span title={title}>{children}</span>
);
export const canCreateReport = [roles.Admin, roles.Owner];
export const canEditComment = [roles.Admin, roles.Owner, roles.Contributor];
export const getHighestRole = (roles) => {
  const roleHierarchy = {
    Admin: 4,
    Owner: 3,
    Contributor: 2,
    Reader: 1,
  };

  let highestRole = null;
  let highestRoleValue = 0;

  roles.forEach((role) => {
    const roleValue = roleHierarchy[role];
    if (roleValue > highestRoleValue) {
      roleValue == 1 ? (highestRole = "Viewer") : (highestRole = role);
      highestRoleValue = roleValue;
    }
  });

  return highestRole;
};
