import React, { useState, useEffect, useContext } from "react";
import cmdStyles from "../styles/commentDetail.module.css";
import { IoMdLink } from "react-icons/io";
import { FiSave, FiEdit, FiInfo } from "react-icons/fi";
import {
  statusColorMap,
  getCurrentTime,
  canCreateReport,
} from "../nexus/nexus";
import { EditTextarea } from "react-edit-text";
import { IoChatbubblesOutline } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import * as Cts from "../nexus/constants.js";
import Select from "react-select";
import { MdOutlineExitToApp } from "react-icons/md";
import axios from "axios";
import { useSelector } from "react-redux";
import CommentLinker from "./CommentLinker";
import vcStyles from "../styles/viewComment.module.css";
import { reportContext } from "./Report";
import LinkBoxNotes from "./LinkBoxNotes";

function CommentDetail(props) {
  const [commentDetail, setCommentDetail] = useState(null);
  const [cmdView, setCmdView] = useState(false);
  const [showLinkBox, setShowLinkBox] = useState(false);
  const [isHeaderChanged, setIsHeaderChanged] = useState(false);
  const [isDescChanged, setIsDescChanged] = useState(false);
  const [isRagChanged, setIsRagChanged] = useState(false);
  const [showLinkComment, setShowLinkComment] = useState(false);
  const configPCC = JSON.parse(localStorage.getItem("pcc"));
  const cmUnlinkings = useSelector((state) => state.commentUnlinkings);
  const userToken = useSelector((state) => state.userToken);
  const userRole = useSelector((state) => state.userRole);
  const report = useContext(reportContext);

  const {
    currentComment,
    handleUpdateComment,
    handleClose,
    show,
    setShow,
    view,
    user,
    project,
    category,
    isPage,
    canEdit,
    commentMeta,
    updateMetaComment,
    prId,
    catId,
    isPackageLocked,
  } = props;
  const handleModalClose = () => {
    setShow(false);
    handleClose();
  };
 
  const handleUpdate = (fieldKey, val, setChanged) => {
    if (setChanged) {
      setChanged(true);
    }

    const updatedCommentDetail = {
      ...commentDetail,
      [fieldKey]: val,
    };

    setCommentDetail(updatedCommentDetail);
  };
  const handleLinking = () => {
    setShowLinkComment(!showLinkComment);
  };
  const headIconsBase = [
    {
      icon: IoMdLink,
      title: cmdView ? "View linked comments" : "Link comment",
      action: handleLinking,
    },
  ];

  const headIcons = headIconsBase;
  // Custom Drop down styling.
  const customRagStyle = {
    control: (provided) => ({
      ...provided,
      border: "none",
      backgroundColor: "none",
      boxShadow: "none",
      width: "100%",
      minWidth: "90px",
      cursor: "pointer",
    }),

    menu: (provided) => ({
      ...provided,
      position: "fixed",
      top: "unset",
      width: "100px",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "#FFFFFF",
      color: state.isSelected ? "#FFFFFF" : "#000000",
      cursor: "pointer",
    }),
  };
  const customRagStyleView = {
    control: (provided) => ({
      ...provided,
      border: "none",
      backgroundColor: "none",
      boxShadow: "none",
      width: "100%",
      minWidth: "90px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const customHeaderStyle = {
    control: (provided) => ({
      ...provided,
      border: "none",
      backgroundColor: "none",
      boxShadow: "none",

      minWidth: "90px",
      cursor: "pointer",
      fontSize: "26px",
    }),

    menu: (provided) => ({
      ...provided,
      marginLeft: "10px",
      marginTop: "30px",
      width: "500px",
      border: "none",
      boxShadow: "none",
      backgroundColor: "#f2f2f2",
      fontSize: "16px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "#f2f2f2",
      color: state.isSelected ? "#FFFFFF" : "#000000",
      cursor: "pointer", // added to change the text color of the selected option
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      position: "relative",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "0",
        height: "0",
        borderLeft: "8px solid transparent", // adjust this value as needed
        borderRight: "8px solid transparent", // adjust this value as needed
        borderTop: "8px solid black",
        cursor: "pointer", // adjust this value as needed
      },
      padding: "3px",
      marginTop: "4px",
      marginLeft: "10px",
    }),
  };
  const customHeaderStyleView = {
    control: (provided) => ({
      ...provided,
      border: "none",
      backgroundColor: "none",
      boxShadow: "none",

      minWidth: "90px",
      cursor: "pointer",
      fontSize: "26px",
      // added to remove the box shadow
    }),

    menu: (provided) => ({
      ...provided,
      marginLeft: "10px",
      marginTop: "30px",
      width: "500px",
      border: "none",
      boxShadow: "none",
      backgroundColor: "#f2f2f2",
      fontSize: "16px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "#f2f2f2",
      color: state.isSelected ? "#FFFFFF" : "#000000",
      cursor: "pointer", // added to change the text color of the selected option
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const updateMetaCommentDetail = (linkedItems, history) => {
    const updatedComment = {
      ...commentDetail,
      linkedComms: linkedItems,
      history: history,
    };

    setCommentDetail(updatedComment);
    if (!isPage) {
      updateMetaComment(linkedItems, history);
    }
  };
  const updateHistoryCommentDetail = (historyItem) => {
    const updatedComment = {
      ...commentDetail,
      history: [historyItem, ...commentDetail.history],
    };

    setCommentDetail(updatedComment);
  };

  const handleSave = () => {
    const commentWithHistory = {
      ...commentDetail,
      history: [
        ...(isDescChanged
          ? [
              {
                time: getCurrentTime(),
                editor: user,
                action: "updated",
                field: "Description",
                value: commentDetail.desc,
              },
            ]
          : []),
        ...(isHeaderChanged
          ? [
              {
                time: getCurrentTime(),
                editor: user,
                action: "updated",
                field: "Header",
                value: commentDetail.header && commentDetail.header.value,
              },
            ]
          : []),
        ...(isRagChanged
          ? [
              {
                time: getCurrentTime(),
                editor: user,
                action: "updated",
                field: "RAG",
                value: commentDetail.rag && commentDetail.rag.label,
              },
            ]
          : []),
        ...commentDetail.history,
      ],
      copied: isHeaderChanged ? false : commentDetail.copied,
    };

    if (isPage) {
      setCommentDetail(commentWithHistory);
      updateCommentById(commentWithHistory);
      sessionStorage.removeItem("finderItems");
      sessionStorage.removeItem("noteItems");
      sessionStorage.removeItem("commentItems");
    } else {
      handleUpdateComment(commentWithHistory);
      setShow(false);
    }
    setCmdView(true);
  };
  const handleEdit = () => {
    setCmdView(false);
  };
  // Your React component

  const updateCommentById = async (updatedComment) => {
    try {
      const response = await fetch("/updateCommentById", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },

        body: JSON.stringify({ updatedComment, cmUnlinkings }),
      });

      if (response.ok) {
        console.log("Update successful.");
      } else {
        const errorData = await response.json();
        // Handle the error data
        console.error("Error:", errorData);
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error:", error);
    }
  };

  const handleCloseLink = () => {};
  useEffect(() => {
    setIsDescChanged(false);
    setIsHeaderChanged(false);
    setIsRagChanged(false);
    setCmdView(view);
    setCommentDetail(currentComment);
    if (isPage) {
      fetchRagHistory(currentComment);
    }
  }, [show]);

  const fetchRagHistory = async (cm) => {
    try {
      const response = await axios.get("/getCommentRagHistoryById", {
        params: {
          id: cm.id,
          reportId: cm.id.split("-")[0],
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });    
      setCommentDetail({
        ...cm,
        ragHistory: response.data.ragHistory,
        history: response.data.history,
      });
    } catch (error) {
      console.error("Error while retrieving comment rag:", error);
    }
  };

  //Render UI
  return (
    <>
      {isPage ? (
        <div className={vcStyles.cmdModalAreaFull}>
          <div className={vcStyles.modalHeader}>
            <div
              className={vcStyles.titleBlockPainter}
              style={{
                backgroundColor:
                  commentDetail && commentDetail.rag && commentDetail.rag.value,
              }}
            ></div>
            <div className={vcStyles.titleBlock}>
              <div className={vcStyles.identifier}>
                <IoChatbubblesOutline className={vcStyles.iconTop} />
                {cmdView ? "View" : "Edit"} Comment:
                {commentDetail && commentDetail.id}
              </div>
              <div
                className={
                  cmdView || isPackageLocked
                    ? vcStyles.cmdTextDisabled
                    : vcStyles.title
                }
              >
                <Select
                  styles={
                    cmdView || isPackageLocked
                      ? customHeaderStyleView
                      : customHeaderStyle
                  }
                  className={vcStyles.cmHeaderDef}
                  placeholder={Cts.cmHeaderPlaceHolder}
                  isSearchable={true}
                  options={configPCC.commentHeaders.sort((a, b) =>
                    a.label > b.label ? 1 : -1
                  )}
                  value={commentDetail && commentDetail.header}
                  onChange={(selOption) =>
                    handleUpdate("header", selOption, setIsHeaderChanged)
                  }
                />
              </div>
            </div>

            {canEdit && commentMeta.reportStatus !== "Completed" && (
              <div className={vcStyles.headTop}>
                {headIcons.map((icon) => {
                  return (
                    <span title={icon.title}>
                      <icon.icon
                        className={
                          cmdView ? vcStyles.iconCmdView : vcStyles.iconCmd
                        }
                        onClick={icon.action}
                      />
                    </span>
                  );
                })}

                <button
                  className={vcStyles.saveButton}
                  onClick={cmdView ? handleEdit : handleSave}
                >
                  {cmdView ? (
                    <FiEdit className={vcStyles.saveButtonIcon} />
                  ) : (
                    <FiSave className={vcStyles.saveButtonIcon} />
                  )}
                  {cmdView ? "Edit" : "Save"}
                </button>
              </div>
            )}
          </div>
          {showLinkComment && (
            <CommentLinker
              type="comment"
              parentId={commentDetail.id}
              title={commentDetail.header.value}
              linkedComms={commentDetail.linkedComms}
              handleClose={handleCloseLink}
              show={showLinkComment}
              setShow={setShowLinkComment}
              view={cmdView}
              ragData={commentDetail.ragHistory}
              historyData={commentDetail.history}
              updateMetaComment={updateMetaCommentDetail}
              isPage={isPage}
              user={user}
            />
          )}
          <div className={vcStyles.header}>
            <div className={vcStyles.state}>
              <span className={vcStyles.label}>RAG:</span>
              <div
                className={vcStyles.colorCircle}
                style={{
                  backgroundColor:
                    commentDetail &&
                    commentDetail.rag &&
                    commentDetail.rag.value,
                }}
              ></div>
              <Select
                styles={cmdView ? customRagStyleView : customRagStyle}
                className={vcStyles.cmHeaderDef}
                placeholder={Cts.cmHeaderPlaceHolder}
                isSearchable={true}
                isDisabled={cmdView}
                options={Cts.ragOptions}
                value={commentDetail && commentDetail.rag}
                onChange={(selOption) =>
                  handleUpdate("rag", selOption, setIsRagChanged)
                }
              />
            </div>
            <div className={vcStyles.metaArea}>
              <span className={vcStyles.label}>Author:</span>
              <span className={vcStyles.headText}>{user}</span>
            </div>
          </div>
          <div className={vcStyles.area}>
            <div className={vcStyles.leftArea}>
              <div className={vcStyles.descArea}>
                <h2 className={vcStyles.areaHeader}>Description</h2>
                <hr className={vcStyles.separator} />
                <EditTextarea
                  value={commentDetail && commentDetail.desc}
                  readonly={cmdView}
                  rows={15}
                  onChange={(e) =>
                    handleUpdate("desc", e.target.value, setIsDescChanged)
                  }
                  placeholder="Enter description"
                />
              </div>
              <div className={vcStyles.noteArea}>
                <h2 className={vcStyles.areaHeader}>Linked Notes</h2>
                {/* <span
                  title="Link/Unlink note"
                  onMouseLeave={() => setShowLinkBox(false)}
                >
                  <FiInfo
                    className={vcStyles.iconinfoEx}
                    onClick={() => setShowLinkBox(!showLinkBox)}
                  />
                </span> */}
                {/* <div
                  onMouseEnter={() => setShowLinkBox(true)}
                  onMouseLeave={() => setShowLinkBox(false)}
                >
                  {showLinkBox && (
                    <LinkBoxNotes
                      commentid={commentDetail.id}
                      isDetail={true}
                      reportId={commentMeta.reportId}
                      currentNotes={commentMeta.notes}
                      prId={commentMeta.prId}
                      catId={commentMeta.catId}
                    />
                  )}
                </div> */}
                <hr className={vcStyles.separator} />
                <p className={cmdStyles.text}>
                  {commentMeta.notes &&
                    commentDetail &&
                    commentMeta.notes.map(
                      (note) =>
                        note.comments.includes(commentDetail.id) && (
                          <React.Fragment key={note.id}>
                            {note.id} -{" "}
                            {note.title.length > 14
                              ? `${note.title.slice(0, 14)}...`
                              : note.title}
                            {", "}
                          </React.Fragment>
                        )
                    )}
                </p>
              </div>
              <div className={vcStyles.historyArea}>
                <h2 className={vcStyles.areaHeader}>History</h2>
                <hr className={vcStyles.separator} />
                <div className={vcStyles.history}>
                  {commentDetail &&
                    commentDetail.history &&
                    commentDetail.history.map((item, index) => (
                      <div key={index} className={vcStyles.historyItem}>
                        <p className={vcStyles.time}>{item.time}</p>
                        <p className={vcStyles.historyDetails}>
                          <span className={vcStyles.modified}>
                            {item.editor}
                          </span>
                          &nbsp;
                          <span className={vcStyles.updated}>
                            {item.action}
                          </span>
                          {item.field && (
                            <>
                              &nbsp;
                              <span className={vcStyles.field}>
                                {item.field}
                              </span>
                              &nbsp;
                              <span className={vcStyles.updated}>to </span>
                            </>
                          )}
                          <span className={vcStyles.value}>
                            {item.value && item.value.length > 100
                              ? `${item.value.slice(0, 100)}...`
                              : item.value}
                          </span>
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={vcStyles.rightArea}>
              <div className={vcStyles.ragArea}>
                <div className={vcStyles.lineHead}>
                  <h2 className={vcStyles.areaHeader}>RAG History</h2>
                </div>
                <hr className={vcStyles.separator} />
                <div className={vcStyles.scrollable}>
                  {commentDetail &&
                    commentDetail.ragHistory &&
                    commentDetail.ragHistory.map((item, index) => (
                      <div
                        className={
                          item.rag == "Blue"
                            ? vcStyles.ragItem
                            : vcStyles.ragItemExternal
                        }
                      >
                        <div className={vcStyles.item} key={index}>
                          <div className={vcStyles.leftSide}>
                            <h3 className={vcStyles.heading}>{item.title}</h3>
                            <p className={vcStyles.label}>
                              Project:
                              <span className={vcStyles.ragValueProject}>
                                {item.project}
                              </span>
                            </p>
                            <p className={vcStyles.label}>
                              Header:
                              <span className={vcStyles.ragValueHeader}>
                                {item.header}
                              </span>
                            </p>
                            <p className={vcStyles.label}>
                              Category:
                              <span className={vcStyles.ragValueCat}>
                                {item.category}
                              </span>
                            </p>
                            <p className={vcStyles.label}>
                              ID:
                              <span className={vcStyles.ragValueId}>
                                {item.cid}
                              </span>
                            </p>
                            <p className={vcStyles.label}>
                              RAG:
                              <span
                                className={vcStyles.ragValueRag}
                                style={{ color: statusColorMap(item.rag) }}
                              >
                                <span
                                  className={vcStyles.colorCircleHistory}
                                  style={{
                                    backgroundColor: statusColorMap(item.rag),
                                  }}
                                ></span>
                                {item.rag}
                              </span>
                            </p>
                          </div>
                          {item.rag == "Blue" && (
                            <div className={vcStyles.arrow}>
                              <MdOutlineExitToApp size={35} />
                            </div>
                          )}
                        </div>
                        <hr className={vcStyles.ragSeparator} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Modal
          dialogClassName={cmdStyles.cmdModalArea}
          show={show}
          onHide={handleModalClose}
        >
          <Modal.Header closeButton>
            <div className={cmdStyles.modalHeader}>
              <div
                className={cmdStyles.titleBlockPainter}
                style={{
                  backgroundColor:
                    commentDetail &&
                    commentDetail.rag &&
                    commentDetail.rag.value,
                }}
              ></div>
              <div className={cmdStyles.titleBlock}>
                <div className={cmdStyles.identifier}>
                  <IoChatbubblesOutline className={cmdStyles.iconTop} />
                  {cmdView ? "View" : "Edit"} Comment:{" "}
                  {commentDetail && commentDetail.id}
                </div>
                {!cmdView ? (
                  <div
                    className={
                      cmdView || isPackageLocked
                        ? cmdStyles.cmdTextDisabled
                        : cmdStyles.title
                    }
                  >
                    <Select
                      styles={
                        cmdView || isPackageLocked
                          ? customHeaderStyleView
                          : customHeaderStyle
                      }
                      className={cmdStyles.cmHeaderDef}
                      placeholder={Cts.cmHeaderPlaceHolder}
                      isSearchable={true}
                      options={configPCC && configPCC.commentHeaders}
                      value={commentDetail && commentDetail.header}
                      onChange={(selOption) =>
                        handleUpdate("header", selOption, setIsHeaderChanged)
                      }
                    />
                  </div>
                ) : (
                  <div className={cmdStyles.cmHeaderViewTitle}>
                    {commentDetail && commentDetail.header.value}
                  </div>
                )}
              </div>

              <div className={cmdStyles.headTop}>
                {headIcons.map((icon) => {
                  return (
                    <span title={icon.title}>
                      <icon.icon
                        className={
                          cmdView ? cmdStyles.iconCmdView : cmdStyles.iconCmd
                        }
                        onClick={icon.action}
                      />
                    </span>
                  );
                })}
                {report.workflow.currentStatus !== "Completed" &&
                  canCreateReport.includes(userRole) &&
                  userRole.length > 0 && (
                    <>
                      <button
                        className={cmdStyles.saveButton}
                        onClick={cmdView ? handleEdit : handleSave}
                      >
                        {cmdView ? (
                          <FiEdit className={cmdStyles.saveButtonIcon} />
                        ) : (
                          <FiSave className={cmdStyles.saveButtonIcon} />
                        )}
                        {cmdView ? "Edit" : "Save & Close"}
                      </button>
                    </>
                  )}
              </div>

              {showLinkComment && (
                <CommentLinker
                  type="comment"
                  parentId={commentDetail.id}
                  title={commentDetail.header.value}
                  linkedComms={commentDetail.linkedComms}
                  handleClose={handleCloseLink}
                  show={showLinkComment}
                  setShow={setShowLinkComment}
                  view={cmdView}
                  ragData={commentDetail.ragHistory}
                  historyData={commentDetail.history}
                  updateMetaComment={updateMetaCommentDetail}
                  isPage={isPage}
                  user={user}
                />
              )}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className={cmdStyles.header}>
              <div className={cmdStyles.state}>
                <span className={cmdStyles.label}>RAG:</span>
                <div
                  className={cmdStyles.colorCircle}
                  style={{
                    backgroundColor:
                      commentDetail &&
                      commentDetail.rag &&
                      commentDetail.rag.value,
                  }}
                ></div>
                <Select
                  styles={cmdView ? customRagStyleView : customRagStyle}
                  className={cmdStyles.cmHeaderDef}
                  placeholder={Cts.cmHeaderPlaceHolder}
                  isSearchable={true}
                  isDisabled={cmdView}
                  options={Cts.ragOptions}
                  value={commentDetail && commentDetail.rag}
                  onChange={(selOption) =>
                    handleUpdate("rag", selOption, setIsRagChanged)
                  }
                />
              </div>
              <div className={cmdStyles.metaArea}>
                <span className={cmdStyles.label}>Author:</span>
                <span className={cmdStyles.headText}>{user}</span>
              </div>
            </div>
            <div className={cmdStyles.area}>
              <div className={cmdStyles.leftArea}>
                <div className={cmdStyles.descArea}>
                  <h2 className={cmdStyles.areaHeader}>Description</h2>
                  <hr className={cmdStyles.separator} />
                  <EditTextarea
                    value={commentDetail && commentDetail.desc}
                    readonly={cmdView}
                    rows={15}
                    onChange={(e) =>
                      handleUpdate("desc", e.target.value, setIsDescChanged)
                    }
                    placeholder="Enter description"
                  />
                </div>
                <div className={cmdStyles.noteArea}>
                  <h2 className={cmdStyles.areaHeader}>Linked Notes</h2>
                  <span
                    title="Link/Unlink note"
                    // onMouseLeave={() => setShowLinkBox(false)}
                  >
                    <FiInfo
                      className={cmdStyles.iconinfoEx}
                      onClick={() => setShowLinkBox(!showLinkBox)}
                    />
                  </span>
                  <div
                    onMouseEnter={() => setShowLinkBox(true)}
                    onMouseLeave={() => setShowLinkBox(false)}
                  >
                    {showLinkBox && (
                      <LinkBoxNotes
                        commentid={commentDetail.id}
                        isDetail={true}
                        reportId={report.id}
                        currentNotes={report.notes}
                        prId={prId}
                        catId={catId}
                        doRefresh={true}
                        updateHistoryCommentDetail={updateHistoryCommentDetail}
                      />
                    )}
                  </div>
                  <hr className={cmdStyles.separator} />
                  <p className={cmdStyles.text}>
                    {report.notes &&
                      commentDetail &&
                      report.notes.map(
                        (note) =>
                          note?.comments?.includes(commentDetail.id) && (
                            <React.Fragment key={note.id}>
                              {note.id} -{" "}
                              {note.title.length > 14
                                ? `${note.title.slice(0, 14)}...`
                                : note.title}
                              {", "}
                            </React.Fragment>
                          )
                      )}
                  </p>
                </div>
                <div className={cmdStyles.historyArea}>
                  <h2 className={cmdStyles.areaHeader}>History</h2>
                  <hr className={cmdStyles.separator} />
                  <div className={cmdStyles.history}>
                    {commentDetail &&
                      commentDetail.history &&
                      commentDetail.history.map((item, index) => (
                        <div key={index} className={cmdStyles.historyItem}>
                          <p className={cmdStyles.time}>{item.time}</p>
                          <p className={cmdStyles.historyDetails}>
                            <span className={cmdStyles.modified}>
                              {item.editor}
                            </span>
                            &nbsp;
                            <span className={cmdStyles.updated}>
                              {item.action}
                            </span>
                            {item.field && (
                              <>
                                &nbsp;
                                <span className={cmdStyles.field}>
                                  {item.field}
                                </span>
                                &nbsp;
                                <span className={cmdStyles.updated}>to </span>
                              </>
                            )}
                            <span className={cmdStyles.value}>
                              {item.value && item.value.length > 100
                                ? `${item.value.slice(0, 100)}...`
                                : item.value}
                            </span>
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={cmdStyles.rightArea}>
                <div className={cmdStyles.ragArea}>
                  <div className={cmdStyles.lineHead}>
                    <h2 className={cmdStyles.areaHeader}>RAG History</h2>
                  </div>
                  <hr className={cmdStyles.separator} />
                  <div className={cmdStyles.scrollable}>
                    {commentDetail &&
                      commentDetail.ragHistory &&
                      commentDetail.ragHistory.map((item, index) => (
                        <div
                          className={
                            item.rag == "Blue"
                              ? cmdStyles.ragItem
                              : cmdStyles.ragItemExternal
                          }
                        >
                          <div className={cmdStyles.item} key={index}>
                            <div className={cmdStyles.leftSide}>
                              <h3 className={cmdStyles.heading}>
                                {item.title}
                              </h3>
                              <p className={cmdStyles.label}>
                                Project:
                                <span className={cmdStyles.ragValueProject}>
                                  {item.project}
                                </span>
                              </p>
                              <p className={cmdStyles.label}>
                                Header:
                                <span className={cmdStyles.ragValueHeader}>
                                  {item.header}
                                </span>
                              </p>
                              <p className={cmdStyles.label}>
                                Category:
                                <span className={cmdStyles.ragValueCat}>
                                  {item.category}
                                </span>
                              </p>
                              <p className={vcStyles.label}>
                                ID:
                                <span className={vcStyles.ragValueId}>
                                  {item.cid}
                                </span>
                              </p>
                              <p className={cmdStyles.label}>
                                RAG:
                                <span
                                  className={cmdStyles.ragValueRag}
                                  style={{ color: statusColorMap(item.rag) }}
                                >
                                  <span
                                    className={cmdStyles.colorCircleHistory}
                                    style={{
                                      backgroundColor: statusColorMap(item.rag),
                                    }}
                                  ></span>
                                  {item.rag}
                                </span>
                              </p>
                            </div>
                            {item.rag == "Blue" && (
                              <div className={cmdStyles.arrow}>
                                <MdOutlineExitToApp size={35} />
                              </div>
                            )}
                          </div>
                          <hr className={cmdStyles.ragSeparator} />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default CommentDetail;
