import React, { useEffect, useState, useContext } from "react";
import notesStyles from "../styles/notes.module.css";
import * as Cts from "../nexus/constants.js";
import { EditText, EditTextarea } from "react-edit-text";
import iconPlus from "../images/iconPlus.png";
import { BiCommentError } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import { RiDeleteBin6Line } from "react-icons/ri";
import { reportContext, reportDispatchContext } from "./Report";
import { customAlphabet } from "nanoid";
import { getCurrentTime } from "../nexus/nexus.js";
import CommentLinker from "./CommentLinker";
function Notes(props) {
  const nanoid = customAlphabet(Cts.idFormat, 4);
  const { view } = props;
  const [showLinkBox, setShowLinkBox] = useState(false);
  const [showDelete, setShowDelete] = useState(true);
  const report = useContext(reportContext);
  const [notes, setNotes] = useState(report.notes);
  const [textareaHeights, setTextareaHeights] = useState({});
  const dispatchReport = useContext(reportDispatchContext);
  const [selectedNote, setSelectedNote] = useState(null);
  const handleUpdateArea = (e, val, noteId) => {
    handleTextAreaChange(e, noteId);
    setShowDelete(false);
    const updatedNotes = notes.map((note) => {
      if (note.id === noteId) {
        return { ...note, desc: val };
      }
      return note;
    });
    setNotes(updatedNotes);
  };
  const handleFinalUpdate = () => {
    setShowDelete(true);
    dispatchReport({
      type: "REPLACE_NOTES",
      payload: {
        notes: notes,
      },
    });
  };
  const handleUpdate = (fieldKey, val, noteId) => {
    dispatchReport({
      type: "UPDATE_NOTE",
      payload: {
        id: noteId,
        key: fieldKey,
        value: val,
      },
    });
  };
  const showLinker = (currentNote) => {
    setSelectedNote(currentNote);
    setShowLinkBox(true);
  };
  const handleTextAreaChange = (e, id) => {
    const { scrollHeight } = e.target;
    const currentHeight =
      parseFloat(textareaHeights[id]) || e.target.clientHeight; // Get current height from state or element
    const extraSpace = scrollHeight > currentHeight ? 40 : 0; // Add extra space only if the scroll height exceeds current height

    if (extraSpace > 0) {
      setTextareaHeights({
        ...textareaHeights,
        [id]: `${scrollHeight + extraSpace}px`,
      });
    }
  };

  const handleClose = () => {};
  const AddNewNote = () => {
    dispatchReport({
      type: "ADD_NOTE",
      payload: {
        id: "N" + nanoid(),
        title: "",
        desc: "",
        comments: [],
        reports: [report.id],
        lastModified: getCurrentTime(),
      },
    });
  };
  const handleDeleteNote = (noteId) => {
    dispatchReport({
      type: "DELETE_NOTE",
      payload: {
        id: noteId,
        reportId: report.id,
      },
    });
  };
  useEffect(() => {
    setNotes(report.notes);
  }, [report.notes]);

  return (
    <div
      className={
        view || !report.from ? notesStyles.notesAreaView : notesStyles.notesArea
      }
    >
      <div
        className={notesStyles.notesBoxPlus}
        title="Add a note"
        onClick={!view && report.from && AddNewNote}
      >
        <img
          className={`${
            view ? notesStyles.commentIconView : notesStyles.commentIcon
          } ${view ? notesStyles.plusDisabled : ""}`}
          src={iconPlus}
          width={Cts.iconWidthSm}
          height={Cts.iconHeightSm}
          title="Add Note"
        />

        {!notes.some((note) => note.reports.includes(report.id)) && (
          <span className={notesStyles.addNoteSpan}>Add Note </span>
        )}
      </div>
      {notes?.length > 0 && (
        <div className={notesStyles.notesPrintHead}>Notes</div>
      )}
      <div className={notesStyles.notesBox}>
        <div className={notesStyles.notesBoxTable}>
          <Table className={notesStyles.notesTable} bordered>
            <tbody>
              {notes.reduce((filteredNotes, note) => {
                const isReportIncluded = note.reports.includes(report.id);

                if (isReportIncluded) {
                  filteredNotes.push(
                    <tr className={notesStyles.notesTR} key={note.id}>
                      <td className={notesStyles.tdNoteNo}>
                        <label>{filteredNotes.length + 1}</label>
                      </td>
                      <td className={notesStyles.tdNoteId}>
                        <label>{note.id}</label>
                      </td>
                      <td className={notesStyles.tdNoteTitle}>
                        <EditText
                          name="txtNoteTitle"
                          placeholder="Enter Title here"
                          value={note.title}
                          readonly={view}
                          onChange={(e) =>
                            handleUpdate("title", e.target.value, note.id)
                          }
                        />
                      </td>
                      <td className={notesStyles.tdNoteDesc}>
                        <EditTextarea
                          placeholder="Enter notes description"
                          value={note.desc}
                          style={{ height: textareaHeights[note.id] || "auto" }}
                          readonly={view}
                          rows={8}
                          onBlur={handleFinalUpdate}
                          onChange={(e) =>
                            handleUpdateArea(e, e.target.value, note.id)
                          }
                        />
                      </td>
                      <td className={notesStyles.tdNoteLink}>
                        {!view && (
                          <>
                            <div
                              title="Link/Unlink comment"
                              onClick={() => showLinker(note)}
                            >
                              <BiCommentError
                                className={notesStyles.iconLink}
                              />
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                }
                return filteredNotes;
              }, [])}
            </tbody>
          </Table>
        </div>

        <div className={notesStyles.notesBoxTableDelete}>
          <Table className={notesStyles.notesTableDelete}>
            <tbody>
              {notes.map((note) => {
                const isReportIncluded = note.reports.includes(report.id);
                return (
                  isReportIncluded && (
                    <tr className={notesStyles.notesTR}>
                      <td>
                        {!view && showDelete && (
                          <div
                            className={notesStyles.notesBoxDelete}
                            title={
                              note.comments?.some((comment) =>
                                comment.startsWith(report.id)
                              )
                                ? "Unlink comments before deleting the note."
                                : "Delete the note from this report"
                            }
                          >
                            <RiDeleteBin6Line
                              className={
                                note.comments?.some((comment) =>
                                  comment.startsWith(report.id)
                                )
                                  ? notesStyles.iconDeleteDisabled
                                  : notesStyles.iconDelete
                              }
                              onClick={() =>
                                note.comments?.some((comment) =>
                                  comment.startsWith(report.id)
                                )
                                  ? ""
                                  : handleDeleteNote(note.id)
                              }
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <div>
        {showLinkBox && (
          <CommentLinker
            type="note"
            parentId={selectedNote.id}
            title={selectedNote.title}
            linkedComms={selectedNote.comments}
            handleClose={handleClose}
            show={showLinkBox}
            setShow={setShowLinkBox}
            view={view}
          />
        )}
      </div>
    </div>
  );
}
export default Notes;
