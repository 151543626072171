import React, { useState, useEffect, useContext } from "react";
import catStyles from "../styles/category.module.css";
import iconPlus from "../images/iconPlus.png";
import Comment from "./Comment";
import * as Cts from "../nexus/constants.js";
import {
  getCurrentTime,
  mapColorToLabel,
  determineTrend,
} from "../nexus/nexus";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { reportContext, reportDispatchContext } from "./Report";

function Category(props) {
  
  const [categories, setCategories] = useState([]);
  const initialShowRagStates = categories.map(() => false);
  const [showRagStates, setShowRagStates] = useState(initialShowRagStates);
  const report = useContext(reportContext);
  const dispatchReport = useContext(reportDispatchContext);
  const { projectId, view, isRender, prIndex } = props;
  const [catCm, setCatCm] = useState([]);
  const [project, setProject] = useState(null);

  const [isCommentDisabled, setIsCommentDisabled] = useState(false);
  const handleUpdate = (fieldKey, catId, val) => {
    dispatchReport({
      type: "UPDATE_CATEGORY",
      payload: {
        prId: projectId,
        id: catId,
        key: fieldKey,
        value: val,
      },
    });

    const newCats = project.categories.map((category) => {
      if (category.id === catId) {
        return { ...category, [fieldKey]: val };
      } else {
        return category;
      }
    });
    const newRag = calculateRag(newCats.map((cat) => cat.rag));
    const updatedProject = {
      ...project,
      categories: newCats,
      rag: newRag,
    };
    setProject(updatedProject);
    updateLastEdited();
  };

  const getCmId = (cat) => {
    const catCmItem = catCm.find((item) => item.cat === cat);
    return catCmItem ? catCmItem.cmId + 1 : 0;
  };

  const updateLastEdited = () => {
    dispatchReport({
      type: "UPDATE_PROJECT",
      payload: {
        id: projectId,
        keys: [
          {
            key: "lastEdited",
            value: getCurrentTime(),
          },
        ],
      },
    });
  };

  const calculateRag = (colors) => {
    return colors.includes("#FF4500")
      ? "#FF4500"
      : colors.includes("#FFA500")
      ? "#FFA500"
      : "#008000";
  };

  useEffect(() => {
    getCategories();

    if (!view) {
      dispatchReport({
        type: "ADD_DEFAULT_COMMENT",
        payload: {
          prId: projectId,
        },
      });
    }
  }, []);

  useEffect(() => {
    const currentProject = report.projects.find((p) => p.id === projectId);
    setProject(currentProject);
    setCatCm(currentProject.catState);
    setIsCommentDisabled(
      report.from === "" ||
        project?.name.label === "Select a project..." ||
        project?.classification.label === "Select a classification..."
    );
  }, [report]);

  const getCategories = () => {
    const cats = Cts.categoriesLocal;
    setCategories(cats);
  };

  const AddComment = (catId, prPrId) => {
    const newComment = {
      id: `${prPrId}-${catId}-C0${getCmId(catId)}`,
      header: "",
      desc: "",
      reportId: report.id,
      prId: projectId,
      catId: catId,
      active: true,
      rag: { value: "#008000", label: "Green" },
      history: [],
      ragHistory: [],
    };
    dispatchReport({
      type: "ADD_COMMENT",
      payload: newComment,
    });
    dispatchReport({
      type: "UPDATE_PROJECT_CATSTATE",
      payload: {
        id: projectId,
        catId: catId,
      },
    });
  };

  const handleMouseEnter = (catId) => {
    const newShowRagStates = [...showRagStates];
    newShowRagStates[catId] = true;
    setShowRagStates(newShowRagStates);
  };

  const handleMouseLeave = (catId) => {
    const newShowRagStates = [...showRagStates];
    newShowRagStates[catId] = false;
    setShowRagStates(newShowRagStates);
  };

  //Render UI
  return (
    <>
      {isRender && (
        <div className={catStyles.catCardContainer}>
          {categories.map((cat, index) => {
            const category = project.categories.find((c) => c.id === cat.id);
            const comments = category ? category.comments : [];
            return (
              <div className={catStyles.catContainer}>
                <div
                  className={view ? catStyles.catRagReadOnly : catStyles.catRag}
                  style={{
                    backgroundColor: category.rag,
                  }}
                  onMouseLeave={() => handleMouseLeave(index)}
                  onMouseEnter={() => !view && handleMouseEnter(index)}
                >
                  {category && category.rag && category.parentRag && (
                    <>
                      {(() => {
                        const trend = determineTrend(
                          category.parentRag,
                          mapColorToLabel(category.rag)
                        );
                        if (trend === "Up") {
                          return (
                            <ArrowUpward className={catStyles.arrow} />
                          );
                        } else if (trend === "Down") {
                          return (
                            <ArrowDownward className={catStyles.arrow} />
                          );
                        } else {
                          return null; // Return null if neither "Up" nor "Down"
                        }
                      })()}
                    </>
                  )}
                  {showRagStates[index] && (
                    <div className={catStyles.colorOptions}>
                      <span className={catStyles.tip}></span>
                      <div
                        className={catStyles.colorOption}
                        style={{ backgroundColor: "#008000" }}
                        onClick={() => handleUpdate("rag", cat.id, "#008000")}
                      />
                      <div
                        className={catStyles.colorOption}
                        style={{ backgroundColor: "#FFA500" }}
                        onClick={() => handleUpdate("rag", cat.id, "#FFA500")}
                      />
                      <div
                        className={catStyles.colorOption}
                        style={{ backgroundColor: "#FF4500" }}
                        onClick={() => handleUpdate("rag", cat.id, "#FF4500")}
                      />
                    </div>
                  )}
                </div>

                <div className={catStyles.cardsContainer}>
                  {comments &&
                    comments.map((cm, index) => {
                      return cm.active && (view ? cm.header.value : true) ? (
                        <Comment
                          id={cm.id}
                          header={cm.header}
                          desc={cm.desc}
                          rag={cm.rag}
                          reportId={report.id}
                          prId={projectId}
                          catId={cat.id}
                          view={view}
                          index={index}
                          prIndex={prIndex}
                          total={comments.length}
                        />
                      ) : (
                        !comments.some((comment) => comment.active) &&
                          index == 0 && (
                            <div className={catStyles.catNoContainer}>
                              <div className={catStyles.catNoRag}></div>
                              <div className={catStyles.catNoContent}>
                                <div className={catStyles.catNoText}>
                                  No issues reported
                                </div>
                              </div>
                            </div>
                          )
                      );
                    })}
                  <div className={catStyles.imageContainer}>
                    <img
                      className={`${
                        view ? catStyles.commentIconView : catStyles.commentIcon
                      } ${isCommentDisabled ? catStyles.plusDisabled : ""}`}
                      src={iconPlus}
                      width={Cts.iconWidthSm}
                      height={Cts.iconHeightSm}
                      onClick={() =>
                        !isCommentDisabled && AddComment(cat.id, projectId)
                      }
                      title={
                        isCommentDisabled
                          ? "Select from date, project, and classification to add a comment."
                          : "Add a new comment"
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Category;
