// store.js
import { configureStore, createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  value: "",
  userRole: [],
  commentLinkings: [],
  commentUnlinkings: [],
  deletedComments: [],
  userToken:null,
  authKey:null,
};

// Define a slice with reducers and actions
const valueSlice = createSlice({
  name: "value",
  initialState,
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setCommentLinkings: (state, action) => {
      state.commentLinkings = action.payload;
    },
    setCommentUnlinkings: (state, action) => {
      state.commentUnlinkings = action.payload;
    },
    setDeletedComments: (state, action) => {
      state.deletedComments = action.payload;
    },
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setAuthKey: (state, action) => {
      state.authKey = action.payload;
    },
  },
});

// Create the Redux store
const store = configureStore({
  reducer: valueSlice.reducer,
});

// Export the actions
export const {
  setValue,
  setUserRole,
  setCommentLinkings,
  setCommentUnlinkings,
  setDeletedComments,
  setUserToken,
  setAuthKey
} = valueSlice.actions;

export default store;
