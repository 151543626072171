import React, { useState, useEffect, useContext } from "react";
import { MdLink, MdLinkOff } from "react-icons/md";
import linkBoxStyles from "../styles/linkBoxNotes.module.css";
import { reportContext, reportDispatchContext } from "./Report";
import { useSelector } from "react-redux";
import { getCurrentTime } from "../nexus/nexus.js";
const LinkBoxNotes = (props) => {
  const {
    commentid,
    isDetail,
    reportId,
    currentNotes,
    prId,
    catId,
    doRefresh,
    updateHistoryCommentDetail,
  } = props;

  const report = useContext(reportContext);
  const dispatchReport = useContext(reportDispatchContext);
  const [notes, setNotes] = useState(currentNotes);
  const currentUser = useSelector((state) => state.value);
  let currentUserName = "";
  if (currentUser) {
    currentUserName = currentUser.split(" (")[0];
  }
  useEffect(() => {
    if (report) setNotes(report.notes);
  }, [report]);

  const handleUpdate = (noteId, comments, link) => {
    const updatedComments = link
      ? [commentid, ...comments]
      : comments.filter((id) => id !== commentid);
    const action = link ? "linked the note " : "unlinked the note ";
    dispatchReport({
      type: "UPDATE_NOTE_WITH_HISTORY",
      payload: {
        id: noteId,
        key: "comments",
        value: updatedComments,
        cid: commentid,
        prId: prId,
        catId: catId,
        action: action,
      },
    });

    const time = getCurrentTime();

    const history = {
      time: time,
      editor: currentUserName,
      action: action,
      field: "",
      value: noteId,
    };
    updateHistoryCommentDetail(history);
    
  };
  return (
    <div
      className={`${linkBoxStyles.linkbox} ${
        isDetail ? linkBoxStyles.linkboxModal : ""
      }`}
    >
      {notes && notes.length === 0 ? (
        <div>No notes available for linking. Please add a note</div>
      ) : (
        notes.map((note, index) => {
          const isReportIncluded = note.reports.includes(reportId);
          const isCommentIncluded = note.comments.includes(commentid);
          return (
            isReportIncluded &&
            note.id && (
              <div
                key={note.id}
                className={`${linkBoxStyles.row} ${
                  !isCommentIncluded ? "" : linkBoxStyles.unlocked
                }`}
                title={!isCommentIncluded ? "Link the note" : "Unlink the note"}
                onClick={() =>
                  handleUpdate(note.id, note.comments, !isCommentIncluded)
                }
              >
                <span className={linkBoxStyles.txtNote}>
                  {index + 1}. {note.id} -
                  {note.title.length > 14
                    ? `${note.title.slice(0, 14)}...`
                    : note.title}
                </span>
                <span className={linkBoxStyles.lockIcon}>
                  {!isCommentIncluded ? (
                    <MdLink style={{ color: "#008000" }} />
                  ) : (
                    <MdLinkOff style={{ color: "#ff726f" }} />
                  )}
                </span>
              </div>
            )
          );
        })
      )}
    </div>
  );
};

export default LinkBoxNotes;
