//imports - libraries
import React, {
  useEffect,
  useState,
  useReducer,
  useRef,
  createContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Snackbar, Alert, CircularProgress, Tooltip } from "@mui/material";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import moment from "moment";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa";
import { FiSave, FiEdit } from "react-icons/fi";

//imports - styles
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-edit-text/dist/index.css";
import reportStyles from "../styles/report.module.css";

//imports - other components
import * as Cts from "../nexus/constants.js";
import iconPlus from "../images/iconPlus.png";
import Project from "./Project.js";
import Notes from "./Notes.js";
import { setCommentLinkings } from "../store/store.js";
import {
  getCurrentTime,
  canCreateReport,
  mapColorToLabel,
} from "../nexus/nexus.js";
import { CancelOutlined } from "@mui/icons-material";

// contexts
export const reportContext = createContext();
export const reportDispatchContext = createContext(null);

// Function definition
export default function Report() {
  const componentRef = useRef();
  const location = useLocation();
  const dispatchStore = useDispatch();

  const [viewMode, setViewMode] = useState("New");
  const [pbiUrl, setPbiUrl] = useState("");
  const [reportReset, setReportReset] = useState(false);
  const [reportDates, setReportDates] = useState(null);
  const [iscopy, setIscopy] = useState(false);
  const [repType, setRepType] = useState("Monthly");
  const [dateStyle, setDateStyle] = useState(null);
  const [prId, setPrId] = useState(1);
  const [parent, setParent] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidAlert, setInvalidAlert] = useState(
    "An error occured while saving the report."
  );
  const configPCC = JSON.parse(localStorage.getItem("pcc"));
  // Redux store fetch
  const {
    value: currentUser,
    userRole,
    commentLinkings: cmLinkings,
    commentUnlinkings: cmUnlinkings,
    deletedComments,
    userToken,
  } = useSelector((state) => ({
    value: state.value,
    userRole: state.userRole,
    commentLinkings: state.commentLinkings,
    commentUnlinkings: state.commentUnlinkings,
    deletedComments: state.deletedComments,
    userToken: state.userToken,
  }));
  const classificationOptions = [
    { value: "LXRP Projects - Active", label: "LXRP Projects - Active" },
    {
      value: "Other Projects in Progress",
      label: "Other Projects in Progress",
    },
    {
      value: "Other Projects - Development",
      label: "Other Projects - Development",
    },
    {
      value: "LXRP Projects - Completed",
      label: "LXRP Projects - Completed",
    },
    {
      value: "Other Projects - Completed",
      label: "Other Projects - Completed",
    },
    { value: "None", label: "None" },
  ];
  const currentUserName = currentUser ? currentUser.split(" (")[0] : "";

  const { customAlphabet } = require("nanoid");
  const nanoid = customAlphabet(Cts.idFormat, 7);

  const [saved, setSaved] = useState(false);
  const [currentPath, setCurrentPath] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [classifications, setClassifications] = useState([]);

  const [progress, setProgress] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const progressColor = progress < 50 ? "#FFA500" : "#4CAF50";

  const handlePDFExport = () => {
    const width = 1800;
    const height = 900;
    const topPadding = 50;
    const leftPadding = 50;
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const topPosition = screenHeight - height - topPadding;
    const leftPosition = screenWidth - width - leftPadding;
    const windowFeatures = `width=${width},height=${height},top=${topPosition},left=${leftPosition},menubar=no,location=no,resizable=yes,scrollbars=yes,status=no`;
    const powerBiUrl = `${pbiUrl}${componentRef.current.id}`;
    // Open the Power BI report URL in a popup window
    window.open(powerBiUrl, "_blank", windowFeatures);
  };

  const fetchReportDates = async () => {
    try {
      const response = await axios.get("/getReportDates", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setReportDates(response.data);
    } catch (error) {
      // Handle error
      console.error("Error fetching report dates:", error);
    }
  };

  //Get project classifications comment header configs
  const fetchPCC = async () => {
    try {
      const response = await axios.get("/getPCC", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const pccData = response.data[0];

      if (pccData.reset) {
        localStorage.setItem("pcc", JSON.stringify(pccData));
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching report dates:", error);
    }
  };
  useEffect(() => {
    if (location.pathname === "/createReport") {
      setViewMode("New");
      if (viewMode === "New") {
        setReportReset(true);
      }
      dispatch({
        type: "RESET",
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    console.log(viewMode);
    setCurrentPath(location.pathname);

    if (location.pathname === "/createReport") {
      setIsLoaded(true);
      if (currentPath === "/report") {
        setDefaults();
      }
      if (reportState.from == "") {
        setDateStyle(reportStyles.highlight);
      }
    }
    if (viewMode === "Edit")
      dispatch({
        type: "SET_REPORT",
        payload: reportState,
      });
  }, [viewMode, reportReset]);

  // Update report Id and add first project on load.
  useEffect(() => {
    if (currentUserName && !reportState?.id) {
      setDefaults();
    }
    fetchSelectedReport();
  }, [currentUserName, isLoaded]);
  useEffect(() => {
    fetchPCC();
  }, []);
  const setDefaults = () => {
    const reportId = `R${nanoid()}`;
    dispatch({ type: "id", payload: reportId });
    if (reportState.projects.length === 0) {
      dispatch({
        type: "ADD_PROJECT",
        payload: {
          id: `${reportId}-P0${prId}`,
          projectId: `P0${prId}`,
          name: {
            value: "default",
            label: "Select a project...",
            isDisabled: true,
          },
          classification: {
            value: "default",
            label: "Select a classification...",
            isDisabled: true,
          },
          lastEdited: getCurrentTime(),
          lastEditedBy: currentUserName,
          rag: "#008000",
          categories: Cts.categoriesLocal,
          active: true,
          isValid: true,
          isValidClass: true,
          isPackageLocked: false,
          catState: [
            { cat: "CT01", cmId: 0 },
            { cat: "CT02", cmId: 0 },
            { cat: "CT03", cmId: 0 },
            { cat: "CT04", cmId: 0 },
            { cat: "CT05", cmId: 0 },
          ],
        },
      });
    }
    fetchReportDates();
  };
  const handleCancel = () => {
    setViewMode("View");
  };
  const fetchSelectedReport = async () => {
    let parentReportId = null;
    let reportdata = null;
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("ID")) {
      setDateStyle(null);
      try {
        await axios
          .get("/getReportById", {
            params: {
              id: queryParams.get("ID"),
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((response) => {
            reportdata = response.data.report;
            setPbiUrl(response.data.PBIUrlExportPDF);
          })
          .catch((error) => {
            // Handle errors here
            console.error("An error occurred:", error);
            return;
          });
        const {
          createdBy,
          from,
          fromDate,
          groupBy,
          id,
          lastModified,
          notes,
          projects,
          reportId,
          reportType,
          title,
          to,
          workflow,
        } = reportdata;
        parentReportId = id;
        let extractedData = null;
        // Extract and convert projectId values to numbers
        const projectNumbers = reportdata.projects.map((project) => {
          const numericPart = project.projectId.replace(/[^\d]/g, "");
          return parseInt(numericPart, 10);
        });
        // Find the maximum number
        const maxProjectNumber = Math.max(...projectNumbers);
        setPrId(maxProjectNumber);
        if (!queryParams.has("copy")) {
          setViewMode("View");
          extractedData = {
            createdBy,
            from,
            fromDate: moment(fromDate).toDate(),
            groupBy,
            id,
            lastModified,
            notes,
            projects,
            reportId,
            reportType,
            title,
            to,
            workflow,
          };
        } else {
          let linkForParentArray = [];
          setIscopy(true);
          setParent(parentReportId);
          const importedTime = getCurrentTime();
          const newReportId = `R${nanoid()}`;
          extractedData = {
            createdBy: currentUserName,
            title,
            from: "",
            fromDate: null,
            groupBy,
            id: newReportId,
            lastModified,
            notes: notes.map((note) => ({
              ...note,
              reports: Array.isArray(note.reports)
                ? [...note.reports, newReportId]
                : [newReportId],
              comments: [
                ...note.comments,
                ...note.comments.map((comment) =>
                  comment.replace(new RegExp(id, "g"), newReportId)
                ),
              ],
            })),
            projects: JSON.parse(
              JSON.stringify(projects).replace(new RegExp(id, "g"), newReportId)
            ).map((project) => {
              const { categories } = project;
              const catState = categories.map((category) => {
                const { id: categoryId, comments } = category;
                const categoryKey = categoryId.split("-")[0];
                let maxCommentNumber = 0;

                comments.forEach(async (comment) => {
                  const { id: commentId } = comment;
                  const targetId = commentId.replace(newReportId, id);

                  const time = getCurrentTime();
                  if (comment.active) {
                    const linkForParent = {
                      source: commentId,
                      target: targetId,
                      time: time,
                      type: "child",
                    };
                    linkForParentArray.push(linkForParent);
                  }

                  const commentNumberMatch = commentId.match(/-C(\d+)$/);

                  if (commentNumberMatch) {
                    const commentNumber = parseInt(commentNumberMatch[1], 10);
                    if (commentNumber > maxCommentNumber) {
                      maxCommentNumber = commentNumber;
                    }
                  }
                });

                return { cat: categoryKey, cmId: maxCommentNumber };
              });
              return {
                ...project,
                catState,
                categories: categories.map((cat) => ({
                  ...cat,
                  parentRag: mapColorToLabel(cat.rag),
                  comments: cat.comments.map((comment) => {
                    // Check if comment.header is not an empty string
                    const setRagHistory =
                      comment.header && comment.header.value.trim() !== "";
                    return {
                      ...comment,
                      linkedComms: [
                        {
                          type: "parent",
                          id: comment.id.replace(newReportId, id),
                          time: getCurrentTime(),
                        },
                      ],
                      copied: true,
                      copiedDesc: comment.desc,
                      history: [
                        {
                          time: importedTime,
                          editor: currentUserName,
                          action: "imported the comment from ",
                          value: comment.id.replace(newReportId, id),
                        },
                      ],
                      // Conditionally create the initial ragHistory item
                      ...(setRagHistory
                        ? {
                            ragHistory: [
                              {
                                title: title,
                                project: project.name.label,
                                header: comment.header.value,
                                category: cat.name,
                                rag: comment.rag.label,
                                cid: comment.id.replace(newReportId, id),
                                time: getCurrentTime(),
                              },
                              ...comment.ragHistory,
                            ],
                          }
                        : {}),
                    };
                  }),
                })),
              };
            }),
            reportId: newReportId,
            reportType,
            title,
            to,
            workflow: {
              currentStatus: "Draft",
              statuses: {
                status: "Draft",
                date: "",
                actionby: "",
              },
            },
            loaded: true,
          };

          dispatchStore(
            setCommentLinkings([...cmLinkings, ...linkForParentArray])
          );
          setDateStyle(reportStyles.highlight);
        }

        dispatch({
          type: "SET_REPORT",
          payload: extractedData,
        });
        setIsLoaded(true);

        const groupedProjects = extractedData.projects
          .filter((project) => project.active)
          .reduce((groups, project) => {
            const classification = project.classification.value;
            if (!groups[classification]) {
              groups[classification] = [];
            }
            groups[classification].push(project);
            return groups;
          }, {});

        const classifiedProjects = configPCC.classifications
          .map(({ value }) => ({
            classification: value,
            projects: groupedProjects[value] || [],
          }))
          .sort((a, b) => (a.classification > b.classification ? 1 : -1));

        setClassifications(classifiedProjects);
      } catch (error) {
        console.error("Error while retrieving report:", error);
      }
    }
  };

  const calculateRag = (colors) => {
    return colors.includes("#FF4500")
      ? "#FF4500"
      : colors.includes("#FFA500")
      ? "#FFA500"
      : "#008000";
  };

  const getTaggedComment = (text1, text2) => {
    const text1Words = text1.split(" ");
    const text2Words = text2.split(" ");
    let formattedText = "<div>";

    text2Words.forEach((word, index) => {
      const isWordInText1 = text1Words.includes(word);
      if (isWordInText1 && index !== text2Words.length - 1) {
        formattedText += `<span style="color: #b0b0b0;">${word} </span>`;
      } else {
        formattedText += `<span style="color: #000000;">${word} </span>`;
        if (index !== text2Words.length - 1) {
          formattedText += " ";
        }
      }
    });
    formattedText += "</div>";
    return formattedText;
  };

  const getCommentCounts = () => {
    const comments = reportState.projects.flatMap((project) =>
      project.categories.flatMap((category) =>
        category.comments.filter(
          (comment) =>
            comment.header &&
            comment.active &&
            comment.header.value.trim() !== ""
        )
      )
    );

    comments.forEach((comment) => {
      if (comment.copiedDesc && comment.desc) {
        comment.taggedDesc = getTaggedComment(comment.copiedDesc, comment.desc);
      }
    });

    const counts = {
      Green: comments.filter((comment) => comment.rag.value === "#008000")
        .length,
      Orange: comments.filter((comment) => comment.rag.value === "#FFA500")
        .length,
      Red: comments.filter((comment) => comment.rag.value === "#FF4500").length,
    };

    return counts;
  };

  // Reducer set up
  const reportInitialState = {
    id: "",
    reportId: "",
    title: "",
    lastModified: getCurrentTime(),
    groupBy: "Project Classification",
    reportType: "Monthly",
    from: "",
    to: "",
    createdBy: currentUserName,
    modifiedBy: "",
    projects: [],
    notes: [],
    ragCountsComment: {},
    workflow: {
      currentStatus: "Draft",
      statuses: {
        status: "Draft",
        date: "",
        actionby: "",
      },
    },
    loaded: false,
  };

  const handleStatusChange = (status) => {
    dispatch({ type: "UPDATE_STATUS", payload: status });
  };
  const handleTypeChange = (type) => {
    setRepType(type);
    dispatch({ type: "UPDATE_TYPE", payload: type });
  };
  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "RESET":
        return reportInitialState;
      case "UPDATE_TYPE":
        const parts = state.title.split(" ");
        const newTitle = `LXRP: ${payload} Report Period ${parts[4]}`;
        return {
          ...state,
          reportType: payload,
          title: newTitle,
        };

      case "UPDATE_STATUS":
        return {
          ...state,
          workflow: {
            ...state.workflow,
            currentStatus: payload,
          },
        };
      case "ADD_PROJECT":
        return { ...state, projects: [...state.projects, payload] };
      case "ADD_NOTE":
        return { ...state, notes: [...state.notes, payload] };
      case "LOAD_REPORT":
        return { ...state, loaded: true };
      case "SET_REPORT":
        return { ...state, ...payload };

      case "UPDATE_PROJECT": {
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((x) => {
            payload.keys.map((key) => {
              if (x.id === payload.id) {
                x[key.key] = key.value;
              }
            });

            return x;
          }),
        };
      }
      case "UPDATE_PROJECT_CATSTATE": {
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === payload.id) {
              const updatedCatState = project.catState.map((category) => {
                if (category.cat === payload.catId) {
                  return { ...category, cmId: category.cmId + 1 };
                }
                return category;
              });

              // Update the catState for the specific project
              return { ...project, catState: updatedCatState };
            }
            return project;
          }),
        };
      }
      case "UPDATE_CATEGORY": {
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.id) {
                  return { ...category, [payload.key]: payload.value };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag:
                  payload.key === "rag"
                    ? calculateRag(newCats.map((cat) => cat.rag))
                    : project.rag,
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "DELETE_NOTE": {
        const { id, reportId } = payload;
        const updatedNotes = state.notes.map((note) => {
          if (note.id === id) {
            // Remove the report from the report array
            note.reports = note.reports.filter((r) => r !== reportId);
            note.active = false;
          }
          return note;
        });
        console.log(updatedNotes);
        return {
          ...state,
          notes: updatedNotes,
        };
      }
      case "DELETE_NOTE1": {
        const { id } = payload;

        const updatedNotes = state.notes.filter((note) => note.id !== id);

        return {
          ...state,
          notes: updatedNotes,
          lastModified: getCurrentTime(),
        };
      }
      case "UPDATE_NOTE": {
        const { id, key, value } = payload;

        const updatedNotes = state.notes.map((note) => {
          if (note.id === id) {
            return { ...note, [key]: value, lastModified: getCurrentTime() };
          }
          return note;
        });

        return {
          ...state,
          notes: updatedNotes,
          lastModified: getCurrentTime(),
        };
      }
      case "REPLACE_NOTES": {
        const { notes } = payload;
        return {
          ...state,
          notes: notes,
          lastModified: getCurrentTime(),
        };
      }
      case "UNLINK_DELETED_COMMENT_NOTES": {
        const { value } = payload;

        const updatedNotes = state.notes.map((note) => {
          if (note.comments.includes(value)) {
            return {
              ...note,
              comments: note.comments.filter((comment) => comment !== value),
              lastModified: getCurrentTime(),
            };
          }
          return note;
        });
        return {
          ...state,
          notes: updatedNotes,
          lastModified: getCurrentTime(),
        };
      }
      case "UPDATE_NOTE_WITH_HISTORY": {
        const { id, key, value, cid, catId, prId, action } = payload;
        const time = getCurrentTime();
        const updatedNotes = state.notes.map((note) => {
          if (note.id === id) {
            return { ...note, [key]: value, lastModified: time };
          }
          return note;
        });

        return {
          ...state,
          notes: updatedNotes,
          lastModified: time,
          projects: state.projects.map((project) => {
            if (project.id === prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === cid) {
                      return {
                        ...cm,
                        history: [
                          {
                            time: time,
                            editor: currentUserName,
                            action: action,
                            field: "",
                            value: id,
                          },

                          ...cm.history,
                        ],
                        lastModified: time,
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "UPDATE_NOTE_WITH_HISTORY_MULTIPLE": {
        const { id, value, comments } = payload;
        const time = getCurrentTime();

        const updatedNotes = state.notes.map((note) => {
          if (note.id === id) {
            return { ...note, comments: value, lastModified: time };
          }
          return note;
        });
        const [_, prId, catId] = comments[0].split("-");
        return {
          ...state,
          notes: updatedNotes,
          lastModified: time,
          projects: state.projects.map((project) => {
            if (project.id === prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === catId) {
                  const newComms = category.comments.map((cm) => {
                    if (comments.includes(cm.id)) {
                      return {
                        ...cm,
                        history: [
                          {
                            time: time,
                            editor: currentUserName,
                            action: "linked the note ",
                            field: "",
                            value: id,
                          },

                          ...cm.history,
                        ],
                        lastModified: time,
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "LINK_COMMENT": {
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === payload.id) {
                      return {
                        ...cm,
                        linkedComms: [...cm.linkedComms, ...payload.value],
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "LINK_OR_UNLINK_COMMENT": {
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === payload.id) {
                      // Determine whether to link or unlink based on payload.isLink
                      let updatedLinkedComms;
                      if (payload.doLink) {
                        updatedLinkedComms = [
                          ...cm.linkedComms,
                          ...payload.value,
                        ];
                      } else {
                        updatedLinkedComms = cm.linkedComms.filter(
                          (linkedId) => !payload.value.includes(linkedId)
                        );
                      }

                      return {
                        ...cm,
                        linkedComms: updatedLinkedComms,
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "UPDATE_COMMENT": {
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === payload.id) {
                      return {
                        ...cm,
                        [payload.key]: payload.value,
                        copied: payload.key === "header" ? false : cm.copied,
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                    rag:
                      payload.key === "rag" || payload.key == "active"
                        ? calculateRag(
                            newComms
                              .filter((cm) => cm.active)
                              .map((cm) => cm.rag.value)
                          )
                        : category.rag,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "UPDATE_COMMENT_LOCKED_PKG": {
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === payload.id) {
                      return {
                        ...cm,
                        [payload.key]: payload.value,
                        copied: payload.key === "header" ? false : cm.copied,
                      };
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                    rag:
                      payload.key === "rag" || payload.key == "active"
                        ? calculateRag(
                            newComms
                              .filter((cm) => cm.active)
                              .map((cm) => cm.rag.value)
                          )
                        : category.rag,
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "REPLACE_COMMENT": {
        const updatedPayload = {
          ...payload,
          author: currentUserName,
          created: getCurrentTime(),
        };
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  const newComms = category.comments.map((cm) => {
                    if (cm.id === payload.id) {
                      return updatedPayload;
                    } else {
                      return cm;
                    }
                  });

                  return {
                    ...category,
                    comments: newComms,
                    rag: calculateRag(
                      newComms
                        .filter((cm) => cm.active)
                        .map((cm) => cm.rag.value)
                    ),
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "ADD_COMMENT": {
        const updatedPayload = {
          ...payload,
          author: currentUserName,
          created: getCurrentTime(),
          linkedComms: [],
        };
        return {
          ...state,
          lastModified: getCurrentTime(),
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                if (category.id === payload.catId) {
                  return {
                    ...category,
                    comments: [...category.comments, updatedPayload],
                  };
                } else {
                  return category;
                }
              });

              return {
                ...project,
                categories: newCats,
                rag: calculateRag(newCats.map((cat) => cat.rag)),
              };
            } else {
              return project;
            }
          }),
        };
      }
      case "ADD_DEFAULT_COMMENT": {
        return {
          ...state,
          projects: state.projects.map((project) => {
            if (project.id === payload.prId) {
              const newCats = project.categories.map((category) => {
                return {
                  ...category,
                  comments: [
                    {
                      id: `${project.id}-${category.id}-C00`,
                      header: "",
                      desc: "",
                      notes: [],
                      rag: { value: "#008000", label: "Green" },
                      reportId: state.id,
                      prId: payload.prId,
                      catId: category.id,
                      active: false,
                      history: [],
                      ragHistory: [],
                      author: currentUserName,
                      created: getCurrentTime(),
                      linkedComms: [],
                    },
                  ],
                };
              });

              return {
                ...project,
                categories: newCats,
              };
            } else {
              return project;
            }
          }),
        };
      }
      default:
        return { ...state, [type]: payload };
    }
  };
  const [reportState, dispatch] = useReducer(reducer, reportInitialState);
  const {
    id,
    title,
    lastModified,
    groupBy,
    reportType,
    from,
    to,
    createdBy,
    modifiedBy,
    projects,
    workflow,
    fromDate,
  } = reportState;

  // Add a new project
  const handleAddProject = () => {
    let newPrId = prId + 1;
    dispatch({
      type: "projects",
      payload: [
        ...projects,
        {
          id: `${reportState.id}-P0${newPrId}`,
          projectId: `P0${newPrId}`,
          name: { value: "", label: "Select a project...", isDisabled: true },
          classification: {
            value: "default",
            label: "Select a classification...",
            isDisabled: true,
          },
          lastEdited: getCurrentTime(),
          lastEditedBy: currentUserName,
          rag: "#008000",
          categories: Cts.categoriesLocal,
          active: true,
          isValid: true,
          isValidClass: true,
          isPackageLocked: false,
          catState: [
            { cat: "CT01", cmId: 0 },
            { cat: "CT02", cmId: 0 },
            { cat: "CT03", cmId: 0 },
            { cat: "CT04", cmId: 0 },
            { cat: "CT05", cmId: 0 },
          ],
        },
      ],
    });
    setPrId(newPrId);
  };

  // handle date change.
  const handleDateChange = (d) => {
    setDateStyle(null);
    const selected = d != null ? moment(d).toDate() : null;
    const formattedDate =
      selected != null ? moment(selected).format("DD/MM/YYYY") : null;
    dispatch({
      type: "title",
      payload: `LXRP: ${repType} Report Period ${formattedDate}`,
    });
    dispatch({
      type: "fromDate",
      payload: d,
    });
    dispatch({
      type: "from",
      payload: formattedDate,
    });
    dispatch({
      type: "reportId",
      payload: `REP${moment(selected).format("YYMMDD")}F`,
    });
  };

  const handleSave = async () => {
    setProgress(1);
    const interval = setInterval(() => {
      setProgress((progress) => Math.min(progress + 10, 100));
    }, 1000);
    setProgress(100);
    setShowMessage(true);
    clearInterval(interval);
    setShowMessage(false);
    setSaved(true);

    const groupedProjects = reportState.projects
      .filter((project) => project.active)
      .reduce((groups, project) => {
        const classification = project.classification.value;
        if (!groups[classification]) {
          groups[classification] = [];
        }
        groups[classification].push(project);
        return groups;
      }, {});

    const classifiedProjects = configPCC.classifications
      .map(({ value }) => ({
        classification: value,
        projects: groupedProjects[value] || [],
      }))
      .sort((a, b) => (a.classification > b.classification ? 1 : -1));

    setClassifications(classifiedProjects);
  };
  // Remove specific items from sessionStorage
  const clearSession = () => {
    sessionStorage.removeItem("finderItems");
    sessionStorage.removeItem("noteItems");
    sessionStorage.removeItem("commentItems");
  };
  //Enable edit
  const handleEnableEdit = () => {
    setSaved(false);
    setProgress(0);
    setViewMode("Edit");
    setIsEdit(true);
    setDateStyle(reportStyles.disableEvents);
  };

  const isReportValid = () => {
    let emptyProjects = "";
    let emptyProjectsClass = "";
    if (!from ?? !from.trim()) {
      setDateStyle(reportStyles.highlight);
      setInvalidAlert(`You must enter a report period`);
      return false;
    }
    const projectIdsWithEmptyName = reportState.projects
      .filter(
        (project) =>
          project.name.label === "Select a project..." && project.active
      )
      .map((project) => {
        emptyProjects += project.projectId + ", ";
        return project.projectId;
      });

    if (projectIdsWithEmptyName.length > 0) {
      emptyProjects = emptyProjects.slice(0, -2); // Remove the trailing comma and space
      setInvalidAlert(
        `Unable to save. You must provide name for the following project/s:  ${emptyProjects}`
      );
      projectIdsWithEmptyName.forEach((projectId) =>
        validateProject(projectId, "isValid")
      );
      return false;
    }

    const projectIdsWithEmptyClass = reportState.projects
      .filter(
        (project) =>
          project.classification.label === "Select a classification..." &&
          project.active
      )
      .map((project) => {
        emptyProjectsClass += project.name.value + ", ";
        return project.projectId;
      });

    if (projectIdsWithEmptyClass.length > 0) {
      emptyProjectsClass = emptyProjectsClass.slice(0, -2); // Remove the trailing comma and space
      setInvalidAlert(
        `Unable to save. You must provide classification for the following project/s: ${emptyProjectsClass}`
      );
      projectIdsWithEmptyClass.forEach((projectId) =>
        validateProject(projectId, "isValidClass")
      );
      return false;
    }
    setInvalidAlert("");
    setIsInvalid(false);
    return true;
  };

  //Create new report
  const handleCreateNewReport = async () => {
    const isTheReportValid = isReportValid();
    if (!isTheReportValid) {
      setIsInvalid(true);
      return;
    }

    const flattenedNotes = reportState.notes.flatMap((note) => note);

    const commentRagCount = getCommentCounts();
    const flattenedComments = reportState.projects.flatMap((project) =>
      project.categories.flatMap((category) =>
        category.comments.filter(
          (comment) => comment.header && comment.header.value.trim() !== ""
        )
      )
    );
    const cmDescriptions = flattenedComments.map((comment) => {
      // Create a new object with only the desired properties
      const { id, reportId, desc, copiedDesc, taggedDesc } = comment;
      return { id, reportId, desc, copiedDesc, taggedDesc };
    });

    const request = {
      report: {
        ...reportState,
        ragCountsComment: commentRagCount,
        ...(iscopy && { title: `LXRP: ${reportType} Report Period ${from}` }),
      },
      notes: flattenedNotes,
      linkings: cmLinkings,
      user: currentUserName,
      unlinkings: cmUnlinkings,
      linkings: cmLinkings,
      parent: parent,
      cmDescriptions: cmDescriptions,
    };

    setDateStyle(null);
    await handleSave();
    setViewMode("View");
    clearSession();
    await axios
      .post("/createNewReport", request, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => setViewMode("View"))
      .catch((err) => {
        console.log(err);
      });
  };

  //Update project
  const validateProject = (val, field) => {
    dispatch({
      type: "UPDATE_PROJECT",
      payload: {
        id: `${reportState.id}-${val}`,
        keys: [
          {
            key: field,
            value: false,
          },
        ],
      },
    });
  };

  //Update report
  const handleUpdateReport = async () => {
    const isTheReportValid = isReportValid();
    if (!isTheReportValid) {
      setIsInvalid(true);
      return;
    }
    if (!from ?? !from.trim()) {
      setDateStyle(reportStyles.highlight);
    } else {
      setDateStyle(null);
      await handleSave();
      setViewMode("View");
    }

    const commentRagCount = getCommentCounts();
    const flattenedNotes = reportState.notes.flatMap((note) => note);
    const flattenedComments = reportState.projects.flatMap((project) =>
      project.categories.flatMap((category) =>
        category.comments.filter(
          (comment) => comment.header && comment.header.value.trim() !== ""
        )
      )
    );

    const cmDescriptions = flattenedComments.map((comment) => {
      // Create a new object with only the desired properties
      const { id, reportId, desc, copiedDesc, taggedDesc } = comment;
      return { id, reportId, desc, copiedDesc, taggedDesc };
    });

    const request = {
      report: { ...reportState, ragCountsComment: commentRagCount },
      notes: flattenedNotes,
      linkings: cmLinkings,
      unlinkings: cmUnlinkings,
      user: currentUserName,
      deletedComments: deletedComments,
      cmDescriptions: cmDescriptions,
    };
    clearSession();
    await axios
      .post("/updateReport", request, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => setViewMode(true))
      .catch((err) => {
        console.log(err);
      });
  };

  //Render UI
  return (
    <>
      {isLoaded ? (
        <reportContext.Provider value={reportState}>
          <reportDispatchContext.Provider value={dispatch}>
            <div
              className={`${reportStyles.report} ${
                viewMode === "View" ? reportStyles.reportReadOnly : ""
              }`}
            >
              <div
                className={`${reportStyles.reportContainer} ${
                  canCreateReport.includes(userRole) && userRole.length > 0
                    ? ""
                    : reportStyles.reportNoAccess
                }`}
              >
                <div
                  id={id}
                  ref={componentRef}
                  className={reportStyles.printContainer}
                >
                  <div className={reportStyles.printHead}>PROTECTED</div>
                  <div className={reportStyles.headContainer}>
                    <div className={reportStyles.head}>
                      <div className={reportStyles.headTitle}>
                        <label className={reportStyles.lblText}>
                          {viewMode === "View"
                            ? Cts.reportViewHead
                            : Cts.reportEditorHead}
                          {reportType} Report Period
                        </label>
                      </div>

                      <div className={reportStyles.headDate}>
                        <div className={dateStyle}>
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => handleDateChange(date)}
                            dateFormat="dd/MM/y"
                            placeholderText=" Select from date"
                            filterDate={(date) =>
                              !reportDates ||
                              !reportDates.includes(
                                moment(date).format("YYYY-MM-DD")
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className={reportStyles.reportCard}>
                      <div className={reportStyles.row}>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol1}>
                            Report ID:
                          </span>
                          <label
                            className={`${reportStyles.reportId} ${
                              workflow.currentStatus === "Draft"
                                ? reportStyles.draft
                                : workflow.currentStatus === "Under Review"
                                ? reportStyles.underReview
                                : reportStyles.completed
                            }`}
                          >
                            {reportState.id}
                          </label>
                        </div>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol2}>
                            Status:
                          </span>

                          {viewMode == "Edit" ? (
                            <>
                              <button
                                className={`btn btn-secondary dropdown-toggle ${reportStyles.statusButton}`}
                                type="button"
                                id="statusDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {workflow.currentStatus}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="statusDropdown"
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleStatusChange("Draft")}
                                  >
                                    Draft
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleStatusChange("Under Review")
                                    }
                                  >
                                    Under Review
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleStatusChange("Completed")
                                    }
                                  >
                                    Completed
                                  </button>
                                </li>
                              </ul>
                            </>
                          ) : (
                            <label
                              className={`${reportStyles.metaValStatusView} ${
                                workflow.currentStatus === "Draft"
                                  ? reportStyles.draft
                                  : workflow.currentStatus === "Under Review"
                                  ? reportStyles.underReview
                                  : reportStyles.completed
                              }`}
                            >
                              {workflow.currentStatus}
                            </label>
                          )}
                        </div>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol3}>
                            Last Modified:
                          </span>
                          <span>{lastModified}</span>
                        </div>
                        {canCreateReport.includes(userRole) &&
                          userRole.length > 0 && (
                            <div className={reportStyles.topButton}>
                              <Tooltip
                                title={viewMode === "View" ? "Edit Report" : "Save Report"}
                              >                                
                                <Button
                                  className={`${reportStyles.button}  ${
                                    viewMode === "View"
                                      ? reportState.workflow.currentStatus !==
                                        "Completed"
                                        ? reportStyles.btnEdit
                                        : reportStyles.btnHide
                                      : reportStyles.btnSave
                                  }`}
                                  variant="primary"
                                  size="lg"
                                  onClick={
                                    viewMode == "New"
                                      ? handleCreateNewReport
                                      : viewMode == "Edit"
                                      ? handleUpdateReport
                                      : handleEnableEdit
                                  }
                                >
                                  <>
                                    {viewMode === "View" ? (
                                      <FiEdit
                                        className={reportStyles.iconSave}
                                      />
                                    ) : (
                                      <FiSave
                                        className={reportStyles.iconSave}
                                      />
                                    )}
                                  </>

                                  <label className={reportStyles.textSave}>
                                    {viewMode === "View" ? "Edit" : "Save"}
                                  </label>
                                </Button>{" "}
                              </Tooltip>
                              {viewMode === "View" ? (
                                <Tooltip title="Export to PDF">
                                  <Button
                                    className={`${reportStyles.buttonExport} ${
                                      viewMode === "View"
                                        ? reportStyles.btnEdit
                                        : reportStyles.btnSave
                                    }`}
                                    variant="primary"
                                    size="lg"
                                    onClick={handlePDFExport}
                                  >
                                    <FaRegFilePdf
                                      className={reportStyles.iconSave}
                                    />
                                    <label className={reportStyles.textSave}>
                                      Export to PDF
                                    </label>
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Cancel & return to view">
                                  <Button
                                    className={`${reportStyles.buttonExport} ${
                                      viewMode === "View"
                                        ? reportStyles.btnEdit
                                        : reportStyles.btnSave
                                    }`}
                                    variant="primary"
                                    size="lg"
                                    onClick={handleCancel}
                                  >
                                    <CancelOutlined
                                      className={reportStyles.iconSave}
                                    />
                                    <label className={reportStyles.textSave}>
                                      Cancel
                                    </label>
                                  </Button>
                                </Tooltip>
                              )}
                            </div>
                          )}
                      </div>
                      <div className={reportStyles.row}>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol1}>
                            Author:
                          </span>
                          <span>{reportState.createdBy}</span>
                        </div>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol2}>
                            Report Type:
                          </span>
                          {viewMode != "View" && !iscopy ? (
                            <>
                              <button
                                className={`btn btn-secondary dropdown-toggle ${reportStyles.reportTypeButton}`}
                                type="button"
                                id="statusDropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {reportType}
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="statusDropdown"
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleTypeChange("Monthly")}
                                  >
                                    Monthly
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleTypeChange("Fortnightly")
                                    }
                                  >
                                    Fortnightly
                                  </button>
                                </li>
                              </ul>
                            </>
                          ) : (
                            <label className={reportStyles.metaValStatusView}>
                              {reportType}
                            </label>
                          )}
                        </div>
                        <div className={reportStyles.column}>
                          <span className={reportStyles.labelCol3}>
                            Group By:
                          </span>
                          <span>{groupBy}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={reportStyles.metaContainer}>
                    <div className={reportStyles.metaProject}>
                      Project
                      <img
                        className={`${
                          viewMode === "View"
                            ? reportStyles.projectIconView
                            : reportStyles.projectIcon
                        } ${
                          reportState.from == ""
                            ? reportStyles.plusDisabled
                            : ""
                        }`}
                        src={iconPlus}
                        width={Cts.iconWidthSm}
                        height={Cts.iconHeightSm}
                        onClick={() =>
                          reportState.from != "" && handleAddProject()
                        }
                        title={
                          reportState.from == ""
                            ? "Select from date to add a new project."
                            : "Add a new project"
                        }
                      />
                    </div>
                    <div className={reportStyles.metaCat}>
                      {Cts.categories.map((cat) => {
                        return (
                          <div className={reportStyles.catLine}>{cat.name}</div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={reportStyles.movableContainer}>
                    {classifications.length > 0 && viewMode == "View" && (
                      <div className={reportStyles.classContainer}>
                        {classifications
                          .filter((cl) => cl.projects.length > 0)
                          .map((cl, index) => {
                            const clIndex = index;
                            return (
                              <div>
                                <div
                                  className={`${reportStyles.topLine}
                                  ${index !== 0 ? null : null}`}
                                />
                                <label className={reportStyles.classHead}>
                                  {cl.classification}
                                </label>
                                <div className={reportStyles.cLine} />
                                <div className={reportStyles.projectContainer}>
                                  {cl.projects.map((pr, index) => {
                                    return (
                                      <>
                                        {index !== 0 && (
                                          <div
                                            className={reportStyles.prLine}
                                          ></div>
                                        )}
                                        <>
                                          <Project
                                            projectId={pr.id}
                                            view={true}
                                            prIndex={`${clIndex}-${index}`}
                                          />
                                        </>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    {viewMode != "View" && (
                      <div className={reportStyles.projectContainer}>
                        {reportState.projects.map((pr, index) => {
                          return (
                            pr.active && (
                              <>
                                {index !== 0 && (
                                  <div className={reportStyles.prLine}></div>
                                )}
                                <Project projectId={pr.id} view={false} />
                              </>
                            )
                          );
                        })}
                      </div>
                    )}

                    <div className={reportStyles.graphContainer}>
                      <div className={reportStyles.hLine}></div>

                      <div className={reportStyles.infoContainer}>
                        <div className={reportStyles.infoLine}>
                          <div
                            className={reportStyles.rectangle}
                            style={{ background: "#ff4500" }}
                          ></div>
                          <div className={reportStyles.infoLabel}>
                            High interest - action required or underway
                          </div>
                          <div className={reportStyles.impact}>
                            <AiOutlineArrowDown
                              className={reportStyles.iconAdd}
                            />
                          </div>
                          <div className={reportStyles.infoLabel}>
                            Rating worsened since last report
                          </div>
                        </div>
                        <div className={reportStyles.infoLine}>
                          <div
                            className={reportStyles.rectangle}
                            style={{ background: "orange" }}
                          ></div>
                          <div className={reportStyles.infoLabel}>
                            Medium concern - close monitoring
                          </div>
                          <div className={reportStyles.impact}>
                            <AiOutlineArrowUp
                              className={reportStyles.iconAdd}
                            />
                          </div>
                          <div className={reportStyles.infoLabel}>
                            Rating improved since last report
                          </div>
                        </div>
                        <div className={reportStyles.infoLine}>
                          <div
                            className={reportStyles.rectangle}
                            style={{ background: "green" }}
                          ></div>
                          <div className={reportStyles.infoLabel}>
                            Low risk - business as usual management
                          </div>
                          <div className={reportStyles.rectangleText}>grey</div>
                          <div className={reportStyles.infoLabel}>
                            No significant change to text since last report
                          </div>
                        </div>
                      </div>
                      <div className={reportStyles.hLineBottom}></div>
                    </div>
                    <Notes view={viewMode == "View" ? true : false} />
                    {!saved && (
                      <div className={reportStyles.progressBar}>
                        <div
                          className={reportStyles.progress}
                          style={{
                            width: `${progress}%`,
                            backgroundColor: progressColor,
                          }}
                        ></div>
                      </div>
                    )}
                    <div
                      className={
                        showMessage
                          ? reportStyles.successMsg
                          : reportStyles.messageHide
                      }
                    >
                      {` Report ${reportState.reportId} ${
                        isEdit ? "updated!" : "created!"
                      }`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </reportDispatchContext.Provider>
        </reportContext.Provider>
      ) : (
        <CircularProgress />
      )}
      <Snackbar
        open={isInvalid}
        autoHideDuration={6000}
        onClose={() => setIsInvalid(false)}
        className={reportStyles.snackbar}
      >
        <Alert
          onClose={() => setIsInvalid(false)}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          {invalidAlert}
        </Alert>
      </Snackbar>
    </>
  );
}
