import React, { useState } from "react";
import logo from "../images/logo.png";
import * as Cts from "../nexus/constants.js";
import appStyles from "../styles/app.module.css";
import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { getHighestRole, generateToken } from "../nexus/nexus.js";
import NavBar from "./NavBar.js";
import { useDispatch } from "react-redux";
import { setValue, setUserRole, setUserToken } from "../store/store";
import { ThreeDots } from "react-loader-spinner";
const jwt = require("jsonwebtoken");
const ErrorComponent = (error) => {
  return <p>An Error Occurred: {error}</p>;
};

const LoadingComponent = () => {
  return (
    <div className={appStyles.introContainer}>
      <div className={appStyles.introText}>
        <ThreeDots color="#186d90" height={250} width={250} />
      </div>
    </div>
  );
};
export default function App() {
  const [photo, setPhoto] = useState(null);
  const [userName, setUserName] = useState(null);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const { instance } = useMsal();

  instance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
    ) {
      instance.setActiveAccount(event.payload.account);

      setUserName(event.payload.account.name);
      dispatch(setValue(event.payload.account.name));
      let highestRole = Cts.noAccess;
      if (event.payload.idTokenClaims.roles) {
        highestRole = getHighestRole(event.payload.idTokenClaims.roles);
      }
      setRole(highestRole);
      dispatch(setUserRole(highestRole));
      generateToken(event.payload.account, highestRole);
      getUserPhoto(event.payload.accessToken);
    }
  });
  const generateToken = async (user, role) => {
    try {
      // Constructing payload for the JWT token
      const tokenPayload = {
        sub: user.homeAccountId, // Subject identifier
        username: user.name, // User's name
        role: role, // User's role
      };

      // Get expiration time for the token
      const expiresIn = Cts.expirationTime;

      // Sign the token using the payload, secret key, and expiration time
      const userToken = jwt.sign(
        tokenPayload,
        process.env.REACT_APP_X_INITIAL, // Secret key
        {
          expiresIn, // Token expiration time
        }
      );

      // Store the generated token in the Redux store
      dispatch(setUserToken(userToken));
    } catch (error) {
      // Log any errors that occur during token generation
      console.error(error.message);
    }
  };

  const getUserPhoto = async (accessToken) => {
    const response = await fetch(Cts.photoUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    response.ok
      ? setPhoto(await response.blob())
      : console.log("Error retrieving user photo:", response.status);
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <div className={appStyles.header}>
        {/* <div className={appStyles.headerImage}>
          <img src={logo} width={Cts.logoWidth} height={Cts.logoHeight} />
        </div> */}
        <div class={appStyles.arrowContainer}>
          <div class={appStyles.arrowText}>
            <h1>
              LEVEL
              <br />
              CROSSING
              <br />
              REMOVAL
            </h1>
            <p>PROJECT</p>
          </div>
          <span class="arrow"></span>
        </div>

        {/* <div className={appStyles.container}>
          <div className={appStyles.leftSection}>
            <div className={appStyles.text}>
              <h1>
                LEVEL
                <br />
                CROSSING
                <br />
                REMOVAL
              </h1>
              <p>PROJECT</p>
            </div>
          </div>
          <div className={appStyles.rightSection}>
            <div className={appStyles.triangle}></div>
          </div>
        </div> */}
        <div className={appStyles.headerText}>
          <h3 className={appStyles.headerLine}>
            LXRP Management Dashboard Tool
          </h3>
          <p className={appStyles.protected}>PROTECTED</p>
        </div>
        {userName && (
          <div className={appStyles.profile}>
            <AuthenticatedTemplate>
              <div className={appStyles.profileCard}>
                <div className={appStyles.userInfo}>
                  <div className={appStyles.userName}>
                    {userName}-{" "}
                    <span className={appStyles.userRole}>{role}</span>
                  </div>
                </div>
                {photo && (
                  <img
                    className={appStyles.userPhoto}
                    src={URL.createObjectURL(photo)}
                    alt="Your Photo"
                  />
                )}
              </div>
            </AuthenticatedTemplate>
          </div>
        )}
      </div>

      {role !== Cts.noAccess && (
        <div className={appStyles.leftNavBar}>
          <NavBar />
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
}
