import React, { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import {
  multiValueFilter
} from "../nexus/finder.js";

export default function useColumnsNote() {
  const navigate = useNavigate();


  const dateSortFunction = (rowA, rowB, columnId) => {
    const dateA = new Date(
      rowA.values[columnId].split("/").reverse().join("-")
    );
    const dateB = new Date(
      rowB.values[columnId].split("/").reverse().join("-")
    );

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };

  const multiSelectFilter = (rows, columnId, filterValue) => {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
          filterValue.includes(String(row.original[columnId]))
        );
  };
  const customDateFilter = (rows, columnIds, filterValue) => {
    const filteredRows = rows.filter((row) => {
      const dateValue = row.original[columnIds[0]];

      const [rowDay, rowMonth, rowYear] = dateValue.split("/");
      const rowDate = new Date(`${rowYear}-${rowMonth}-${rowDay}`);

      if (Array.isArray(filterValue) && filterValue.length === 2) {
        const [startDate, endDate] = filterValue;
        const start = new Date(startDate);
        const end = new Date(endDate);

        return rowDate >= start && rowDate <= end;
      } else {
        const [firstDate] = filterValue;
        const start = new Date(firstDate);

        return rowDate >= start;
      }
    });

    return filteredRows;
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filter: multiSelectFilter,
        show: true,
        width: 150,
        // width: "auto",
        Cell: (props) => {
          return <p style={{ fontWeight: 600 }}>{props.value}</p>;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        width: 200,
        filter: multiSelectFilter,
        show: true,
      },

      {
        Header: "Comment",
        accessor: "comment",
        filter: multiValueFilter,
        width: 300,
        show: true,
      },
      {
        Header: "Report",
        accessor: "report",
        filter: multiValueFilter,
        width: 265,
        show: true,
        Cell: (props) => {
          const reportValues = props.value.split(", ");

          return (
            <>
              {reportValues.map((value, index) => (
                <React.Fragment key={index}>
                  {index > 0 && ", "}
                  <Tooltip title={`View ${value}`}>
                  <Nav.Link
                    onClick={() => navigate(`/report?ID=${value}`)}
                    style={{
                      color: "#0073CF",
                      textDecoration: "none",
                      cursor: "pointer",
                      display: "inline-block", // Ensure links appear on the same line
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                  >
                    <p style={{ fontWeight: 400 }}>{value}</p>
                  </Nav.Link>
                  </Tooltip>
                </React.Fragment>
              ))}
            </>
          );
        },
      },

      {
        Header: "Description",
        accessor: "desc",
        filter: multiSelectFilter,
        show: true,
        width: 300,
      },
      {
        Header: "Date Modified",
        accessor: "lastModifiedDate",
        filter: customDateFilter,
        show: true,
        width: 200,
        sortType: dateSortFunction,
      },
    ],
    []
  );

  return columns;
}
